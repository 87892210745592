@charset "utf-8";

/* ------------------------------------------------------------------
 * Name         : Gambo - Online Grocery Supermarket HTML Template
 * File         : style.css
 * Author       : Gambolthemes
 * Author URI   : gambol943@gmail.com
 * All Rights Reserved.
 * --------------------------------------------------------------------- */

/*===================== TABLE OF CONTENT =======================

1. Google Fonts
2. Typography Css
3. CROSS BROWSER COMPATABILITY
4. Body Scrollbar
5. Night Mode Btn
6. Search cate scroll
7. Header
8. Category Model
9. Category Model
10. Cart Offset Overlay
11. Cart Radio Btn
12. Quantity box
13. Filters Overlay
14. Category Radio Btn
15. Index Page
16. Slider Category
17. Footer
18. Product Grid Page
19. Single Product View
20. Single Product Radio Btn
21. Checkout Page
22. User Dashboard Page
23. Dashboard My Orders
24. My Rewards
25. My Wallet
26. My Addresses
27. Career Page
28. About Us
29. Our Blog
30. Blog Detail View
31. Press Page
32. Sign In
33. Contact Us
34. Product Request
35. Faq Page
36. Offers Page
37. Bill Page

=============================================================*/

/* ================================================================================== 
 Google Fonts
===================================================================================== */

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,900&display=swap')

/* ================================================================================== 
Reset Css
===================================================================================== */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
::-moz-selection { /* Code for Firefox */
	background-color: #cce2ff;
    color: rgba(0,0,0,.87);
}

::selection {
	background-color: #cce2ff;
    color: rgba(0,0,0,.87);
}

ol, ul {
	list-style: none;
	margin-bottom: 0 !important;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
button {
outline: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
* {
	margin:0;
	padding: 0;
}
a:focus {
	text-decoration: none !important;
	outline: none;
}
a:hover {
	text-decoration: none !important;
}
button {
	outline: none;
}

button:focus {
	outline: none !important;
}

input {
	outline: none;
}
textarea {
	outline: none;
}

p {
	font-size: 14px;
	line-height: 26px;
	color: #91699c;
}

a {
	cursor: pointer !important;
	text-decoration: none !important;
}

ul {
	padding-left: 0 !important;
}

/* ================ Typography Css =============== */
h1 {
	font-size: 2em;
	font-family: 'Roboto', sans-serif !important;
	/* 2x body copy size = 32px */
	line-height: 1.25;
	/* 45px / 36px */
}
h2 {
	font-size: 1.625em;
	font-family: 'Roboto', sans-serif !important;
	/* 1.625x body copy size = 26px */
	line-height: 1.15384615;
	/* 30px / 26px */
}
h3 {
	font-size: 1.375em;
	font-family: 'Roboto', sans-serif !important;
	/* 1.375x body copy size = 22px */
	line-height: 1.13636364;
	/* 25px / 22px */
}
h4 {
	font-size: 1.125em;
	font-family: 'Roboto', sans-serif !important;
	/* 1.125x body copy size = 18px */
	line-height: 1.11111111;
}
h5 {
	font-size: 1.10em;
	font-family: 'Roboto', sans-serif !important;
	/* 1.125x body copy size = 18px */
	line-height: 1.10;
}
h6 {
	font-size: 1em;
	font-family: 'Roboto', sans-serif !important;
	/* 1.125x body copy size = 18px */
	line-height: 1.050;
}
blockquote {
	font-size: 1.25em;
	font-family: 'Roboto', sans-serif !important;
	/* 20px / 16px */
	line-height: 1.25;
	/* 25px / 20px */
}
@media (min-width: 43.75em) {
h1 {
	font-size: 2.5em;
	/* 2.5x body copy size = 40px */
	line-height: 1.125;
}
h2 {
	font-size: 2em;
	/* 2x body copy size = 32px */
	line-height: 1.25;
}
h3 {
	font-size: 1.5em;
	/* 1.5x body copy size = 24px */
	line-height: 1.25;
}
h4 {
	line-height: 1.22222222;
	/* (22px / 18px */
}
blockquote {
	font-size: 1.5em;
	/* 24px / 16px = */
	line-height: 1.45833333;
	/* 35px / 24px */
}
}
@media (min-width: 56.25em) {
h1 {
	font-size: 3em;
	/* 3x body copy size = 48px */
	line-height: 1.05;
	/* keep to a multiple of the 20px line height 
	and something more appropriate for display headings */
}
h2 {
	font-size: 2.25em;
	/* 2.25x body copy size = 36px */
	line-height: 1.25;
}
h3 {
	font-size: 1.75em;
	/* 1.75x body copy size = 28px */
	line-height: 1.25;
}
}


/* ================ CROSS BROWSER COMPATABILITY =============== */

.ask-question,
nav,
.notification-box.active,
nav ul li a,
.user-account-settingss.active,
.user-info a,
.suggestion-usd > span i,
.view-more > a,
.tags-sec ul li a,
.post-st ul li a,
.ed-opts > a,
.bk-links li a,
.like-com li a,
.user-profy > a,
.slick-previous:before,
.slick-nexti:before,
.comment > a,
.sign_link > a,
.post-popup,
.post-project > a,
.post-project-fields form ul li button,
.post-project-fields form ul li a,
.sign-control li a,
.cover-sec > a,
.star-descp > a,
.message-btn a,
.pf-gallery ul li,
.gallery_pt:before,
.gallery_pt > a,
.overview-box,
.skl-name,
.conv-list,
.save-stngs ul li button,
.forum-links.active,
.react-links li a,
.next-prev > a,
nav ul li:hover > ul,
.user-pro-img > a i {
-webkit-transition: all 0.4s ease-in;
-moz-transition: all 0.4s ease-in;
-ms-transition: all 0.4s ease-in;
-o-transition: all 0.4s ease-in;
transition: all 0.4s ease-in;
}

/* === Body Scrollbar === */

body::-webkit-scrollbar {
    width: 14px;
    height: 18px;
}
body::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}
body::-webkit-scrollbar-corner {
    background-color: transparent;
}
body::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid transparent;
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: rgba(0,0,0,.15);
    -webkit-box-shadow: inset -1px -1px 0 rgba(0,0,0,.05), inset 1px 1px 0 rgba(0,0,0,.05);
}

html {
   font-size: 14px;
}

body {
	margin: 0;
	font-family: 'Roboto', sans-serif !important;
	font-size: 0.875rem;
	line-height: 1.42857143;
	color: #2b2f4c;
	background: #f7f7f7 !important;
}

body::-webkit-scrollbar {
    width:4px;
}

body::-webkit-scrollbar-thumb {
    height: 50px;
    background: hsla(0, 0%, 53.3%, 0.4);
}

input[type=text] {
	color: #2b2f4c !important;
}


/* --- Night Mode Btn --- */

.night_mode_switch__btn {
    border-bottom: 1px solid #efefef;
}

.night_mode_switch__btn a {
    display: block;
    font-size: 14px;
    transition: 0.3s;
    font-weight: 500;
    border-radius: 10px;
    color: #333;
    padding: 15px;
    position: relative;
	display: flex;
    align-items: center;
	width: 220px;
}

.night_mode_switch__btn a i {
    font-size: 120%;
	height: 30px;
	Width: 30px;
	background: #ffecec;
	border-radius: 100%;
	margin-right: 10px;
	padding: 2px 4px 0;
	display: inline-block;
}

.btn-night-mode .btn-night-mode-switch {
    display: inline-block;
    height: 18px;
    width: 37px;
    top: 35%;
    right: 13px;
    position: absolute;
}

/*--- search cate scroll ---*/

.search-by-cat::-webkit-scrollbar {
    width:4px;
}

.search-by-cat::-webkit-scrollbar-thumb {
    height: 50px;
    background: hsla(0, 0%, 53.3%, 0.4);
}

.bs-canvas-body::-webkit-scrollbar {
    width:4px;
}

.bs-canvas-body::-webkit-scrollbar-thumb {
    height: 50px;
    background: hsla(0, 0%, 53.3%, 0.4);
}

.scrollstyle_4::-webkit-scrollbar {
    width: 4px;
    background-color: #f1f1f1;
}

.scrollstyle_4::-webkit-scrollbar-thumb {
    background-color: #e5e5e5;
}

.scrollstyle_4::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
    background-color: #F5F5F5;
}

/*--- Header ---*/

.header {
	position: fixed;
	z-index: 100;
	width: 100%;
	background: #fff;
	box-shadow: 0px 0.052083333in 11.25pt -9px rgb(0 0 0 / 10%);
}
.header2 {
	position: fixed;
    z-index: 100;
    width: 100%;
    background: #fff;
    box-shadow: 0px 0.052083333in 11.25pt -9px rgb(0 0 0 / 10%);
    top: 77px;
    transition: top 1s ease;
    padding: 2px 11px 10px;
}

.top-header-group {
    width: 100%;
    display: block;
}

.top-header {
    width: 100%;
    display: flex;
	height: 60px;
    align-items: center;
}

.main_logo {
    width: 125px;
    margin-left: 10px;
}

.blog_logo {
    padding: 0px;
}

.logo-inverse {
    display: none;
}

.select-location {
    margin-left: 30px;
}

.select-location .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 150px !important;
}

.search120 {
    margin-left: 55px;
}

.s-icon {
    position: absolute;
    text-align: center;
    right: 3px;
    top: 3px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    padding: 1px 0;
    font-size: 18px;
    background: transparent;
    border-radius: 4px;
    color: #8f91ac;
}

.gambo-head {
    height: 60px;
}

.main_logo img {
    width: 100%;
	padding-top: 0;
}

.res_main_logo {
	display: none;
	width: 45px;
}

.res_main_logo img {
    width: 100%;
	padding-top: 3px;
}

.srch10 {
    background: #f7f7f7 !important;
    border: 0 !important;
    color: #2b2f4c !important;
    height: 36px;
    padding: 10px 40px 10px 15px;
    width: 350px;
    border-radius: 5px;
}

i.icon1 {
    font-size: 16px !important;
    position: absolute;
    top: 6px;
    left: 10px;
}

.swdh10 {
    font-size: 14px !important;
    width: 100%;
}

.ui[class*="left icon"].swdh10.input>input {
    padding-left:3.2em!important;
    padding-right: 1em!important;
	font-size: 14px;
    font-weight: 500;
	border: 0 !important;
}

.ui.search .prompt {
    border-radius: .3rem !important;
	width: 100%;
}

.header_right {
    margin-left: auto;
    padding-right: 15px;
}

.header_right ul li {
    display: inline-block;
	vertical-align: middle;
}

.option_links {
    padding: 10px 9px;
    font-size: 20px;
    color: #91699c;
	position: relative;
}

.option_links i {
    font-size: 18px;
}

.option_links .icon_wishlist {
    background: rgba(246,151,51,0.2);
    color: #f69733;
    height: 35px;
    width: 35px;
    display: inline-block;
    padding: 6px 5px;
    border-radius: 100%;
}

.option_links .icon_noti {
    background: rgba(2,114,54,0.2);
    color: #027236;
    height: 35px;
    width: 35px;
    display: inline-block;
    padding: 10px 5px;
    border-radius: 100%;
}

.option_links:hover, .option_links:focus {
    color: #2b2f4c !important;
}

.dropdown_account_rl {
    width: 250px;
}

.noti_count {
    font-size: 11px;
	font-weight: 600;
    position: absolute;
    top: 0px;
    right: 8px;
    background: #027236;
    color: #fff;
    width: 16px;
    height: 16px;
    text-align: center;
    line-height: 1.5;
    border-radius: 100%;
    font-weight: 500;
}

.noti_count1 {
    font-size: 11px;
	font-weight: 600;
    position: absolute;
    top: 0px;
    right: 8px;
    background: #f69733;
    color: #fff;
    width: 16px;
    height: 16px;
    text-align: center;
    line-height: 1.5;
    border-radius: 100%;
}

.opts_account {
    font-weight: 500;
    color: #2b2f4c;
	float: right;
    padding: 5px 5px 5px 10px;
    background: #f9f9f9;
    border-radius: 10px;
    margin-left: 17px;
}

.opts_account img {
    width: 30px;
    border-radius: 50%;
    border: 1px solid #fff;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    margin-left: 0;
}

.opts_account .user__name {
    margin-left: 10px;
	font-size: 13px;
}

.opts_account:hover {
    color: #f55d2c !important;
}

.channel_item {
    display: block;
    font-size: 14px;
    width: 100%;
    color: #2b2f4c;
    padding: 10px 20px;
}

.channel_item:hover {
	color: #f55d2c !important;
	background-color: #f7f7f7;
}

.channel_item:focus {
	color: #f55d2c !important;
	background-color: transparent;
}

.icon__1 {
    font-size: 18px;
    margin-right: 10px;
}

.left_title {
    padding: 20px 17px 10px;
    font-size: 12px;
    font-weight: 500;
    color: #afafaf;
    text-align: left;
	margin-bottom: 0;
}

.user_img img {
    width: 30px;
    border-radius: 100%;
    margin-left: 17px;
    margin-right: 15px;
}

.dropdown-menu-account {
    margin-top: 7px !important;
}

.menu.dropdown_account_rl {
    min-width: 220px !important;
}

.rl-account-step p {
    font-size: 13px;
    font-weight: 400;
}

.rl-account {
    text-align: center;
    padding: 15px;
}

.rl-account-step {
    padding-bottom: 20px;
    margin-bottom: 15px;
    border-bottom: 1px solid #efefef;
}

.rl-account-step:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0;
}

.lr-acount-btn {
    display: inline-block;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    padding: 8px 10px;
    font-size: 14px;
    line-height: 16px;
    border-radius: 4px;
    color: #fff;
    background-color: #f55d2c;
}

.menu.dropdown_mn {
    width: 300px;
	margin-top: 12px !important;
    border-top: 1px solid #fff !important;
    border-radius: 3px !important;
	min-width: 300px !important;
}

.menu.dropdown_ms {
    width: 300px;
	margin-top: 12px !important;
    border-top: 1px solid #fff !important;
    border-radius: 3px !important;
	min-width: 300px !important;
}

.channel_my {
	padding: 15px !important;
    border-bottom: 1px solid #efefef !important;
    min-width: 100%;
    max-width: 100%;
    white-space: normal;
}

.channel_my:last-child {
    border-bottom: 0 !important;
}

.profile_link {
    display: flex;
}

.profile_link img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
	border: 2px solid #fff;
	border-radius: 100%;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
}

.pd_content {
    width: 80%;
	white-space: normal;
}

.pd_content h6 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
    color: #2b2f4c;
    text-align: left;
	position: relative;
}

.pd_content span {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 0;
    color: #686f7a;
    text-align: left;
}

.dp_link_12 {
    font-size: 14px;
    font-weight: 500;
    color: #2b2f4c;
    margin-top: 15px;
    display: block;
    text-align: left;
}

.dp_link_12:hover {
    color: #ed2a26 !important;
}

.ui.dropdown .menu>.channel_item {
    color: #2b2f4c !important;
    padding: .78571429rem 1.14285714rem!important;
    font-size: 14px !important;
    font-weight: 400 !important;
}

.ui.dropdown .menu>.channel_item:hover {
    color: #2b2f4c !important;
    background:#ffecec !important;
}

.rhte85 {
    display: flex;
}

.mef78 i {
    font-size: 18px;
    line-height: 15px;
    color: #1da1f2;
}

.pd_content p {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 8px;
    color: #91699c;
    text-align: left;
    margin-top: 8px;
	line-height: 20px;
}

.pd_content span.nm_time {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0;
    color: #afafaf;
    text-align: left;
}

.vbm_btn {
    width: 100%;
    text-align: center;
    padding: 15px 0;
    background: #f55d2c;
    font-size: 14px;
    color: #fff !important;
    font-weight: 500;
}

.ui.input>input {
    font-family: 'Roboto', sans-serif !important; 
}

.select_location {
    margin-left: 34px;
}

.ui.inline.dropdown.loc-title>.text {
    font-weight: 500 !important;
    color: #2b2f4c;
    font-size: 14px;
}

.menu.dropdown_loc {
    width: 170px;
    margin-top: 20px !important;
    border-top: 1px solid #fff !important;
    border-radius: 3px !important;
    min-width: 170px !important;
}

.offer-link {
    font-size: 14px;
    font-weight: 400;
    margin-right: 18px;
    color: #2b2f4c;
	margin-left: 6px;
}

.offer-link:hover {
    color: #f55d2c !important;
}

.offer-link i {
    font-size: 16px;
	margin-right: 3px;
}

.sub-header-group {
    width: 100%;
    display: block;
	border-top: 1px solid #efefef;
}

.sub-header {
	width: 100%;
	display: block;
	align-items: center;
}

.nav-link {
    color: #2b2f4c !important;
}

.offcanvas-help-links {
    border: 1px solid #efefef;
    border-radius: 10px;
    padding: 20px 20px !important;
    margin: 15px 0 27px !important;
}

.offcanvas-help-links li {
    line-height: 38px;
}

.offcanvas-help-links li .offer-link {
    font-size: 1.1em;
    font-weight: 500;
}

.offcanvas-copyright {
    margin-bottom: 20px;
}

.main_nav .nav-link {
    display: block;
    padding: 10px 15px !important;
	font-size: 14px;
	cursor: pointer;
	font-weight: 400;
}

.nav-link.active {
    color: #f55d2c !important;
}

.main_nav .nav-link:hover {
    color: #f55d2c !important;	
}

.header_cart {
    
}

.cart__btn {
	color: #fff !important;
	font-size: 14px;
	font-weight: 500;
	background: #f55d2c;
    padding: 22px 30px;
	text-align: center;
}

.cart__btn span {
    margin: 0 2px;
}

.hover-btn:hover {
	background: #f55d2c;
	color: #fff !important;
	transition: all .2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
	background-image: -webkit-linear-gradient(left, rgba(230, 92, 91, 0.9), rgba(245, 93, 44, 0.9));
    background-image: linear-gradient(to right, rgba(230, 92, 91, 0.9), rgba(245, 93, 44, 0.9));
}

.cart__btn ins {
    color: #fff !important;
    margin-left: 5px;
	text-decoration: none !important;
}

.category_drop {
    padding: 20px 30px 19px;
    font-size: 14px;
    color: #2b2f4c;
    position: relative;
    font-weight: 500;
	margin-right: 10px;
	border-right: 1px solid #efefef;
}

.category_drop i {
	margin-right: 10px;
}

.offcanvas-header {
    border-bottom: 1px solid #efefef;
    background: #ffffff;
}

.offcanvas-logo img {
    width: 50px;
}

.category_drop_canvas {
    height: 50px;
	width: 100%;
    font-size: 14px;
    color: #fff;
	background: #f55d2c;
    position: relative;
    font-weight: 500;
    margin-right: 0;
	border-radius: 5px;
    border: none;
}

.category_drop_canvas i {
	margin-right: 10px;
}

.canvas_search {
    background: #f7f7f7 !important;
    border: 0 !important;
    color: #2b2f4c !important;
    height: 50px;
    padding: 10px 40px 10px 15px;
    width: 100%;
    border-radius: 5px;
}

.canvas-icon {
    position: absolute;
    text-align: center;
    right: 5px;
    top: 5px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    padding: 0;
    font-size: 20px;
    color: #fff;
	cursor: pointer;
    background: #f55d2c;
    border-radius: 5px;
}

.close-btn {
    padding: 5px;
    font-size: 20px;
    background: #f7f7f7;
    color: rgba(0,0,0,0.4);
    border-radius: 5px;
    width: 40px;
    height: 40px;
    border: 0;
}

.close-btn:hover {
    background: #f7f7f7;
    color: #2b2f4c;
}

.navbar-toggler {
    padding: 7px 0 !important;
    font-size: 26px !important;
    background: #f7f7f7 !important;
    color: rgba(0,0,0,0.4) !important;
    border-radius: 5px !important;
    width: 40px;
    height: 40px;
    border: 0 !important;
    margin-left: 13px;
    text-align: center;
	box-shadow: none !important;
}

.navbar-toggler:hover {
    background: #f7f7f7;
    color: #2b2f4c;
}

.dropdown-menu {
    border-radius: 5px !important;
    background-color: #fff !important;
    border: 1px solid #f1f1f1 !important;
    box-shadow: 0 8px 11px 2px rgb(64 70 74 / 2%) !important;
}

.dropdown-submenu {
    width: 230px;
    margin-top: 12px !important;
    border-radius: 5px !important;
    background-color: #fff !important;
    border: 1px solid #f1f1f1 !important;
    box-shadow: 0 8px 11px 2px rgb(64 70 74 / 2%) !important;
}

.dropdown-submenu .dropdown-item {
    display: block;
    font-size: 14px;
    width: 100%;
    color: #2b2f4c;
    padding: 10px 20px;
}

.dropdown-submenu .dropdown-item:hover {
	color: #fff !important;
	background-color: #f7f7f7;
}

.dropdown-submenu .dropdown-item:focus {
	color: #f7f7f7 !important;
	background-color: transparent;
}

.menu_toggle_btn {
    padding: 5px !important;
    line-height: 1;
    border: 0 !important;
    border-radius: 5px !important;
    font-size: 26px !important;
    background: #f9f9f9 !important;
    color: #2b2f4c !important;
}

.mt1-5 {
    margin-top: 124px !important;
    text-align: center;
}

.bmt1-5 {
    margin-top: 60px !important;
    text-align: center;
}

.bg-dark1 {
    background-color: #fff !important;
    z-index: 999;
}

/* --- Category Model --- */
.btn-close {
    background: transparent !important;
}

.main-gambo-model {
    background-image: -webkit-linear-gradient(left, rgba(230, 92, 91, 0.9), rgba(245, 93, 44, 0.9));
    background-image: linear-gradient(to right, rgba(230, 92, 91, 0.9), rgba(245, 93, 44, 0.9));
}

.category-area-inner .modal-header {
	border-bottom: 0;
}

.category-area-inner .btn-close {
	color: #fff !important;
    opacity: 1 !important;
    padding: 30px 0 15px !important;
    font-size: 30px !important;
	cursor: pointer !important;
}

.modal-content {
    border-radius: 10px !important;
}

.category-model-content {
    background: #fff;
    border: 0 !important;
}

.catey__icon {
	display: none;
}

.search__icon {
	display: none;
}

.sub-header-icons-list {
    display: inline-block;
    font-size: 20px;
}

.cate__btn {
	font-size: 20px;
    color: #8f91ac !important;
	padding: 20px 20px 19px;
}

.cate__btn:hover {
    color: #f55d2c !important;
}

.search__btn {
	font-size: 20px;
    color: #fff !important;
	padding: 20px 20px 21px;
	background: #2b2f4c;
}

/* --- Category Mode --- */

.cate-header {
    background: #2b2f4c;
    color: #fff;
    padding: 15px 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.cate-header h4 {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
	margin-bottom: 0;
}

.category-by-cat {
   width: 100%;
   display: inline-table;
}

.category-by-cat li {
    width: 33.333%;
	vertical-align: middle;
	display: inline-block;
	list-style: none;
	float: left;
}

.single-cat-item {
    text-align: center;
    padding: 20px 10px;
	display: block;
}

.single-cat-item:hover {
	background: #f9f9f9;
}

.single-cat-item .text {
	font-size: 14px;
	font-weight: 500;
	color: #2b2f4c;
}

.single-cat-item .icon {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
}

.single-cat-item .icon img {
    width: 50px;
}

.morecate-btn {
    display: block;
    text-align: center;
    border-top: 1px solid #efefef;
    padding: 20px;
    font-size: 16px;
    font-weight: 500;
    color: #2b2f4c;
}

.morecate-btn i {
    margin-right: 5px;
}

.morecate-btn:hover {
    color: #f55d2c !important;
}

.search-ground-area {
	max-width: 400px !important;
}

.search-header {
    position: relative;
    width: 100%;
    border-bottom: 1px solid #efefef;
}

.search-header input {
    width: 100%;
    border: 0;
    padding: 20px;
    position: relative;
}

.search-header button {
    position: absolute;
    right: 0px;
    background: transparent;
    border: 0;
    padding: 17px;
    font-size: 20px;
}

.search-by-cat {
    width: 100%;
	height: 321px;
    overflow: hidden scroll;
}

.search-by-cat .single-cat {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 0;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
	padding: 15px 20px;
}

.search-by-cat .single-cat .icon {
    background-color: #f9f9f9;
    border-radius: 5%;
    color: #fff;
    font-size: 22px;
    height: 50px;
    line-height: 47px;
    text-align: center;
    width: 50px;
}

.search-by-cat .single-cat .icon img {
    width: 30px;
}

.search-by-cat .single-cat .text {
	color: #2b2f4c;
    font-weight: 400;
    padding-left: 20px;
    font-size: 16px;
}

.search-by-cat .single-cat:hover .text {
	color: #f55d2c;
}


/* --- Cart Offset Overlay --- */

.bg-cart {
	background: #f7f7f7;
}

.bs-canvas-overlay {
	opacity: 0.85;
	z-index: 1100;
}

.bs-canvas {
	top: 0;
	z-index: 1110;
	overflow-x: hidden;
	overflow-y: auto;
	width: 400px;
	transition: margin .4s ease-out;
	-webkit-transition: margin .4s ease-out;
	-moz-transition: margin .4s ease-out;
	-ms-transition: margin .4s ease-out;
}

.bs-canvas-left {
	left: 0;
	margin-left: -400px;
}


.bs-canvas-close {
    color: #fff !important;
    opacity: 1 !important;
}
		
.side-cart-header {
    background: #2b2f4c;
    color: #fff;
	position: sticky;
    top: 0;
}

.main-cart-title {
    font-size: 18px;
    font-weight: 600;
}

.main-cart-title span{
	color: #f69733;
    margin-left: 5px;
}

.bs-canvas-body {
    background: #f7f7f7;
    position: absolute;
    width: 100%;
    top: 48px;
    bottom: 190px;
    overflow-y: scroll;
}

.bs-canvas-footer {
    background: #fff;
    position: absolute;
    width: 100%;
    bottom: 0;
	border-top: 1px solid #efefef;
}

.cart-top-total {
    border-bottom: 1px solid #efefef;
    padding: 0 0 20px;
}

.cart-total-dil {
    display: flex;
    width: 100%;
    align-items: center;
}

.cart-total-dil h4 {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    color: #2b2f4c;
    line-height: 24px;
}

.cart-total-dil span {
	color: #8f91ac;
    font-size: 16px;
    margin-left: auto;
    font-weight: 400;
	line-height: 24px;
}

.main-total-cart {
    display: flex;
    padding: 20px !important;
    border-bottom: 1px solid #e5e5e5;
}

.saving-total {
    padding: 20px 20px 0;
}

.main-total-cart h2 {
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 600;
    color: #2b2f4c;
    line-height: 24px;
}

.main-total-cart span {
	color: #f55d2c;
    font-size: 18px;
    margin-left: auto;
    font-weight: 700;
	line-height: 24px;
}

.checkout-cart {
	display: flex;
    padding: 20px;
	align-items: center;
}

.promo-code {
	font-size: 14px;
	font-weight: 600;
	color: #2b2f4c;
	line-height: 24px;
}

.promo-code:hover {
	color: #f55d2c !important;
}

.cart-checkout-btn {
	display: inline-block;
    margin-bottom: 0;
    margin-left: auto;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
    background: #f55d2c;
    font-weight: 500;
}

.cart-item {
	display: flex;
	padding: 20px;
	border-bottom: 1px solid #efefef;
}

.cart-item:last-child {
	border-bottom: 0;
}

.side-cart-items {
    
}

.cart-product-img {
    width: 130px;
	position: relative;
}

.cart-product-img img {
    width: 100%;
    border-radius: 10px;
	border: 1px solid #f7f7f7;
}

.offer-badge {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 10px;
    font-weight: 700;
    color: #fff;
    background: #f55d2c;
    padding: 1px 10px 0px;
    border-radius: 3px;
    line-height: 20px;
}

.cart-text {
	margin-left: 20px;
	width: 100%;
	position: relative;
}

.cart-text h4 {
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	color: #2b2f4c;
	text-align: left;
	margin-bottom: 10px;
}

.cart-close-btn {
    position: absolute;
    top: 2px;
    right: 0;
    background: transparent;
    border: 0;
    font-size: 18px;
    color: #2b2f4c;
}

.cart-close-btn:hover {
    color: #f55d2c;
}


/* --- Cart Radio Btn --- */

.kggrm-now {
  list-style-type: none;
  margin:  0;
  padding: 0;
}

.kggrm-now li {
	margin-right: 5px;
    width: 40px;
    height: 30px;
    position: relative;
    text-align: center;
    display: inline-block;
}

.kggrm-now label,
.kggrm-now input {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.kggrm-now input[type="radio"] {
	display: none;
}

.kggrm-now input[type="radio"]:checked+label,
.Checked+label {
	background: #f55d2c;
}

.kggrm-now label {
	padding: 6px 5px;
    cursor: pointer;
    background: #c7c7c7;
    color: #fff;
    border-radius: 6px;
    font-weight: 600;
	font-size: 12px;
}

.kggrm-now label:hover {
	background: #f55d2c;
}

.qty-group {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

/* -- quantity box -- */

.quantity {
 display: inline-block; }

.quantity .input-text.qty {
	width: 35px;
    height: 30px;
    padding: 0 5px;
    text-align: center;
    background-color: transparent;
    border: 0;
    margin-left: -4px;
    margin-right: -4px;
    background: #fff;
    font-size: 12px;
	font-weight: 500;
}

.quantity .input-text.qty.white-bg-input {
    border: 1px solid #efefef;
}

.quantity.buttons_added {
	 text-align: left;
	 position: relative;
	 white-space: nowrap;
	 vertical-align: top; 
 }

.quantity.buttons_added input {
	 display: inline-block;
	 margin: 0;
	 vertical-align: top;
	 box-shadow: none;
}

.quantity.buttons_added .minus,
.quantity.buttons_added .plus {
    padding: 6px 10px 5px;
    height: 30px;
    background-color: #2b2f4c;
    border: 0;
	color: #fff;
    cursor: pointer;
    border-radius: 3px;
	width: 30px;
}

.quantity.buttons_added .minus-btn {
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.quantity.buttons_added .plus-btn {
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.quantity.buttons_added .minus {
	border-right: 0;	
}

.quantity.buttons_added .plus {
	border-left: 0; 
}

.quantity.buttons_added .minus:hover,
.quantity.buttons_added .plus:hover {
	background: #f55d2c;
	color: #fff !important;
	transition: all .2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
	background-image: -webkit-linear-gradient(left, rgba(230, 92, 91, 0.9), rgba(245, 93, 44, 0.9));
    background-image: linear-gradient(to right, rgba(230, 92, 91, 0.9), rgba(245, 93, 44, 0.9));
 }

.quantity input::-webkit-outer-spin-button,
.quantity input::-webkit-inner-spin-button {
	 -webkit-appearance: none;
	 -moz-appearance: none;
	 margin: 0;
 }
 
.quantity.buttons_added .minus:focus,
.quantity.buttons_added .plus:focus {
	outline: none; 
}


.cart-item-price {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    color: #f55d2c;
    text-align: right;
	display: inline-flex;
	margin-left: auto;
}

.cart-item-price span {
    color: #c7c7c7;
    text-decoration: line-through;
    margin-left: 10px;
    font-weight: 600;
}

.wishlist-item {
    display: flex;
}

.wishlist-product-img {
    width: 70px;
	position: relative;
}

.wishlist-product-img img {
    width: 100%;
    border-radius: 5px;
	border: 1px solid #efefef;
}

/* --- Filters Overlay --- */

.bs-canvas-right {
	right: 0;
	margin-right: -400px;
}

.bs-canvas-close {
    color: #fff !important;
    opacity: 1 !important;
}
		
.side-cart-header {
    background: #2b2f4c;
    color: #fff;
	position: sticky;
    top: 0;
}

.main-cart-title {
    font-size: 16px;
    font-weight: 500;
}

.bs-canvas-body {
    background: #f9f9f9;
    position: absolute;
    width: 100%;
    top: 48px;
    bottom: 190px;
    overflow-y: scroll;
}

.filter-body {
    background: #fff !important;
    bottom: 0 !important;
	padding-bottom: 30px;
}

.filter-items {
    padding: 1.5rem 1.5rem 0;
}

.filter-items:last-child {
    padding: 1.5rem 1.5rem 1.5rem;
}

.filtr-cate-title h4 {
    font-size: 16px;
    font-weight: 500;
    color: #2b2f4c;
    margin-bottom: 0;
}

.filter-item-body {
    height: 200px;
	width: 100%;
	margin-top: 20px;
	overflow-y: auto;
}

.other-item-body {
    height: 166px;
    width: 100%;
    margin-top: 20px;
    overflow-y: auto;
}

.offer-item-body {
    height: 125px;
    width: 100%;
    margin-top: 20px;
    overflow-y: auto;
}

.price-pack-item-body {
    height: 135px;
    width: 100%;
    margin-top: 20px;
    overflow-y: auto;
}

/* --- category Radio Btn --- */

.cte-select {
  list-style-type: none;
  margin:  0;
  padding: 0;
}

.cte-select li {
	margin-right: 0;
    width: 100%;
    height: 40px;
    position: relative;
    text-align: left;
    display: block;
    margin-bottom: 0px;
    border-bottom: 0;
}

.cte-select label,
.cte-select input {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.cte-select input[type="radio"] {
	display: none;
}

.cte-select input[type="radio"]:checked+label,
.Checked+label {
	background: #f55d2c;
	color: #fff;
}

.cte-select label {
	padding: 7px 20px;
    cursor: pointer;
    background: #f9f9f9;
    color: #2b2f4c;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 5px;
}

.cte-select label:hover {
	background: #f55d2c;
	color: #fff;
}

.brand-list {
    width: 100%;
    padding: 0 0 0 5px;
}

.search-by-catgory {
    margin-bottom: 15px;
    width: 100%;
}

.custom-control-label {
    font-size: 14px;
    font-weight: 400;
    color: #2b2f4c !important;
    vertical-align: middle !important;
    padding-top: 2px;
    padding-left: 5px;
}

.custom-control-label::before {
    width: 1.2rem !important;
    height: 1.2rem !important;
}

.custom-checkbox .custom-control-label::before {
    box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0.1) !important;
}

.custom-control-label::after {
	top: .29rem !important;
    left: -1.4rem !important;
    width: 1.2rem !important;
    height: 1.2rem !important;
    content: "";
}

.custom-control-input:checked~.custom-control-label::before {
    border-color: #f55d2c !important;
    background-color: #f55d2c !important;
}

.pb2 {
    padding-bottom: 10px;
}

.pb2:last-child {
    padding-bottom: 10px;
}

.webproduct {
    color: #8f91ac;
    padding-left: 3px;
}

/* --- Index Page --- */

.wrapper {
    padding-top: 121px;
	padding-bottom: 64px;
}

.wrapper-blog {
    padding-top: 60px !important;
}

.main-banner-slider {
    margin-top: 30px;
}

.offer-item {
    background: #fff;
    padding: 5px;
    border-radius: 10px;
	position: relative;
}

.offer-item-img {
    position: relative;
    width: 100%;
}

.offer-item-img img {
    border-radius: 10px;
    width: 100%;
}

.gambo-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #2b2f4c;
    border-radius: 5px;
    opacity: 0.05;
}

.offer-top-text-banner {
    position: absolute;
    top: 20px;
    left: 5px;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    padding: 15px 15px 11px;
    width: 90%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.offer-top-text-banner p {
    font-size: 12px;
    font-weight: 600;
    color: #f55d2c;
    margin-bottom: 3px;
    text-align: left;
    text-transform: uppercase;
}

.top-text-1 {
    font-size: 16px;
    font-weight: 500;
    color: #2b2f4c;
    margin-bottom: 5px;
    line-height: 24px;
    text-align: left;
}

.offer-top-text-banner span {
    font-weight: 500;
    font-size: 14px;
    color: #8f91ac;
    text-align: left;
    line-height: 24px;
}

.Offer-shop-btn {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: inline-block;
    padding: 10px 30px;
    background: #f55d2c;
    text-align: center;
    color: #fff;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
}

/* --- Slider Category --- */

.section145 {
    padding: 65px 0 0;
	width: 100%;
	display: block;
}

.main-title-tt {
    margin-bottom: 27px;
	display: flex;
    align-items: flex-end;
}

.main-title-left {
    margin-right: auto;
}

.main-title-left span {
    font-size: 13px;
    font-weight: 500;
    text-align: left;
    color: #fff;
    background: #f55d2c;
    margin-bottom: 10px;
    display: inline-block;
    line-height: 27px;
    padding: 0 10px;
    border-radius: 5px;
}

.main-title-left h2 {
    font-size: 24px;
    font-weight: 600;
    color: #2b2f4c;
    margin-top: 0;
	display: block;
}

.see-more-btn {
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
    color: #2b2f4c;
    text-align: right;
}

.see-more-btn:hover {
    color: #f55d2c !important;
}

.category-item {
    width: 100%;
	display: block;
    background: #fff;
    border-radius: 10px;
    text-align: center;
    padding: 20px;
}

.cate-img {
    width: 50px;
    text-align: center;
    display: inline-block;
}

.cate-img img {
    display: inline-block;
}

.category-item h4 {
    font-size: 14px;
    font-weight: 500;
    color: #2b2f4c;
	margin-top: 15px;
    text-align: center;
}

.cate-slider .owl-nav {
    margin-top: 0 !important;
}

.cate-slider .owl-nav .owl-prev {
	left: -20px;
    font-size: 20px !important;
    position: absolute;
    top: 35%;
    text-align: center;
    height: 30px !important;
    width: 30px !important;
    padding: 0 !important;
    color: #2b2f4c !important;
    background: #fff !important;
    border-radius: 5px !important;
    border: 1px solid #e5e5e5 !important;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.cate-slider .owl-nav .owl-next {
	font-size: 20px !important;
	position: absolute;
	right: -20px;
	top: 35%;
	padding: 0 !important;
	text-align: center;
	height: 30px !important;
	width: 30px !important;
	color: #2b2f4c !important;
	background: #fff !important;
	border-radius: 5px !important;
	border: 1px solid #e5e5e5 !important;
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.cate-slider .owl-nav .owl-prev:hover {
	color: #fff !important;
	opacity: 1;
	background: #f55d2c !important;
	border: 1px solid #f55d2c !important;
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}	

.cate-slider .owl-nav .owl-next:hover {
	color: #fff !important;
	opacity: 1;
	border: 1px solid #f55d2c !important;
	background: #f55d2c !important;
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

span.like-icon {
	color: #f69733;
    font-size: 18px;
    position: absolute;
    z-index: 101;
    right: 0;
    top: 0;
    cursor: pointer;
    background-color: rgba(246,151,51,0.2);
    display: block;
    height: 35px;
    width: 35px;
    line-height: 37px;
    border-radius: 30px;
    transition: all 0.4s;
}

.like-icon:before, .like-icon:after {
	transition: opacity 0.3s, transform 0.4s cubic-bezier(.0, -.41, .19, 2.5);
	font-family: "Font Awesome 5 Free";
	position: absolute;
	right: 0;
	left: 0;
	text-align: center;
	top: 0px;
}

.like-icon:before {
	content: "\f004";
	transform: scale(0.95);
}

.like-icon:after {
	content: "\f004";
	opacity: 0;
	transform: scale(0.3);
	font-weight: 900;
}

.like-icon.liked:after {
	opacity: 1;
	transform: scale(0.95);
}

.like-icon:hover, .like-icon.liked {
	background-color:#f69733;
	color:#fff;
}

.like-icon.liked:before {
	opacity: 0;
}

.product-item {
    width: 100%;
    display: block;
    background: #fff;
    border-radius: 10px;
    text-align: center;
    padding: 15px;
	box-shadow: 0 1px 2px 0 #e9e9e9;
}

.product-img {
    width: 100%;
    position: relative;
	padding: 10px 20px;
	display: block;
}

.product-img img {
	width: 100%;
	transition: transform .5s ease;
}

.product-item:hover img {
	transform: scale(1.1);
}

.offer-badge-1 {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    background: #f55d2c;
    padding: 3px 10px;
    border-radius: 5px;
    line-height: 20px;
	text-transform: uppercase
}

.product-text-dt p {
	font-size: 12px;
    font-weight: 500;
    color: #8f91ac;
    margin-bottom: 0;
    line-height: 24px;
}

.product-text-dt p span {
    margin-left: 5px;
}

.product-text-dt h4 {
	margin-top: 0;
	font-size: 16px;	
    font-weight: 700;
    margin-bottom: 10px !important;
    line-height: 24px;
    text-align: center;
    color: #2b2f4c;
}

.product-price {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: #f55d2c;
    text-align: center;
}

.product-price span {
    color: #c7c7c7;
    text-decoration: line-through;
    margin-left: 10px;
    font-weight: 600;
}

.qty-cart {
	display: flex;
	align-items: center;
	margin-top: 17px;
}

.cart-icon {
    margin-left: auto;
    font-size: 20px;
    color: #c7c7c7;
	cursor: pointer;
}

.cart-icon:hover {
    color: #f55d2c;
}

.featured-slider .owl-nav .owl-prev {
	left: -20px;
    font-size: 20px !important;
    position: absolute;
    top: 42%;
    text-align: center;
    height: 30px !important;
    width: 30px !important;
    padding: 0 !important;
    color: #2b2f4c !important;
    background: #fff !important;
    border-radius: 5px !important;
    border: 1px solid #e5e5e5 !important;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.featured-slider .owl-nav .owl-next {
	font-size: 20px !important;
	position: absolute;
	right: -20px;
	top: 42%;
	padding: 0 !important;
	text-align: center;
	height: 30px !important;
	width: 30px !important;
	color: #2b2f4c !important;
	background: #fff !important;
	border-radius: 5px !important;
	border: 1px solid #e5e5e5 !important;
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.featured-slider .owl-nav .owl-prev:hover {
	color: #fff !important;
	opacity: 1;
	background: #f55d2c !important;
	border: 1px solid #f55d2c !important;
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}	

.featured-slider .owl-nav .owl-next:hover {
	color: #fff !important;
	opacity: 1;
	border: 1px solid #f55d2c !important;
	background: #f55d2c !important;
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.best-offer-item {
    display: block;
    width: 100%;
	margin-bottom: 1.5rem;
	position: relative;
}

.best-offer-item img {
    width: 100%;
	border-radius: 10px;
}

.offer-counter-text {
    font-size: 30px;
    width: 100%;
    text-align: center;
    color: #fff;
    font-weight: 600;
    letter-spacing: 0px;
	text-shadow: 1px 3px 6px rgba(0,0,0,0.2);
}

.cmtk_dt {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
}

.code-offer-item img {
    width: 100%;
    border-radius: 10px;
}

/* --- Footer --- */

.footer {
    background: #050505;
}

.footer-first-row {
    padding: 20px 0;
	border-bottom: 1px solid #3f4464;
}

.call-email-alt {
    float: left;
    width: 100%;
	padding-top: 4px;
}

.call-email-alt li {
    display: inline-block;
    margin-right: 20px;
}

.call-email-alt li:last-child {
    margin-right: 0;
}

.callemail {
    font-size: 14px;
    font-weight: 500;
    color: #a2d5c6;
    display: flex;
    align-items: center;
}

.callemail:hover {
    font-size: 14px;
    font-weight: 500;
    color: #fff !important;
}

.callemail i {
    margin-right: 5px;
    color: #f55d2c;
    font-size: 18px;
}

.social-links-footer {
    text-align: right;
    float: left;
    width: 100%;
}

.social-links-footer h4 {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 17px;
}

.social-links-footer ul {
    float: left;
    margin-top: 5px;
	width: 100%;
}

.social-links-footer ul li {
    display: inline-block;
    padding-right: 7px;
}

.social-links-footer ul li:last-child {
    padding-right: 0;
}

.social-links-footer ul li a {
    color: #a2d5c6;
    width: 36px;
    height: 36px;
    background: #3f4464;
    border-radius: 50%;
    padding: 9px 0;
    text-align: center;
    font-size: 14px;
    position: relative;
    display: inline-block;
    bottom: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.social-links-footer ul li:hover a {
    bottom: 5px;
	color: #fff !important;
}

.footer-second-row {
    padding-bottom: 50px;
	border-bottom: 1px solid #3f4464;
}

.second-row-item {
    margin-top: 47px;
}

.second-row-item h4 {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 17px;
}

.second-row-item ul li {
    display: block;
    padding-bottom: 9px;
}

.second-row-item ul li:last-child {
    padding-bottom: 0;
}

.second-row-item ul li a {
    color: #a2d5c6;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
	position: relative;
	left: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.second-row-item ul li a:hover {
    color: #fff !important;
	left: 5px;
}

.second-row-item-2 {
    margin-top: 86px;
}

.second-row-item-app {
    margin-top: 47px;
}

.second-row-item-app h4 {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 17px;
}

.second-row-item-app ul li {
    display: inline-block;
    padding-right: 0;
}

.second-row-item-app ul li:last-child {
    padding-right: 0;
}

img.download-btn {
    width: 120px;
}

.footer-last-row {
	padding: 30px 0;
	background: #2b2f4c;
	text-align: center; 
}

.footer-bottom-group {
    display: flex;
    width: 100%;
    align-items: center;
}

.footer-bottom-links ul li {
    display: inline-block;
    padding: 0 10px;
}

.footer-bottom-links li a {
    color: #a2d5c6;
	font-size: 13px;
    font-weight: 500;
    text-align: left;
	line-height: 24px;
	position: relative;
	bottom: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.footer-bottom-links ul li:hover a {
    bottom: 2px;
	color: #fff !important;
}

.copyright-text {
    color: #a2d5c6;
    font-weight: 500;
    font-size: 12px;
    padding: 0;
	margin-left: auto;
    letter-spacing: 0.3px;
}

.financial-institutes {
    display: inline-block;
    list-style: none;
    line-height: 0;
}

.financial-institutes__logo {
    display: inline-block;
    margin: 0 1px 5px 0;
}

.financial-institutes__logo img {
    width: 35px;
    height: auto;
}

.second-row-item-payment {
    margin-top: 37px;
}

.second-row-item-payment h4 {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    margin-bottom: 17px;
}

/* --- Product Grid Page --- */

.gambo-Breadcrumb {
    padding: 30px 0;
    background: #2b2f4c;
    position: relative;
}

.gambo-Breadcrumb:before {
    content: "";
    /*background: url(../images/career/default.jpg) no-repeat center;*/
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    pointer-events: none;
    background-size: cover;
    opacity: 0.1;
}

.gambo-Breadcrumb .breadcrumb {
    background-color: transparent;
    border-radius: 0;
    font-size: 14px;
    font-weight: 500;
    color: rgba(255,255,255,0.8);
}

.gambo-Breadcrumb .breadcrumb .breadcrumb-item a {
    color: rgba(255,255,255,0.8) !important;
}

.gambo-Breadcrumb .breadcrumb .breadcrumb-item a:hover {
    color: rgba(255,255,255,1) !important;
}

.gambo-Breadcrumb .breadcrumb .breadcrumb-item.active {
    color: #fff;
}

.breadcrumb-item+.breadcrumb-item::before {
    color: rgba(255,255,255,0.8) !important;
}

.all-product-grid {
    margin-top: 64px;
}

.product-top-dt {
    float: left;
    width: 100%;
    display: block;
}

.product-left-title {
    float: left;
}

.product-left-title h2 {
    font-size: 24px;
    font-weight: 600;
    color: #2b2f4c;
    text-align: left;
	padding-top: 3px;
}

.filter-btn {
    font-size: 14px;
    font-weight: 500;
    float: right;
    display: block;
    height: 40px;
    width: 110px;
    padding: 10px 0;
    text-align: center;
	color: #2b2f4c;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #efefef;
    text-transform: uppercase;
}

.filter-btn:hover {
    background: #f55d2c;
	color: #fff !important;
	transition: all .2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
	background-image: -webkit-linear-gradient(left, rgba(230, 92, 91, 0.9), rgba(245, 93, 44, 0.9));
    background-image: linear-gradient(to right, rgba(230, 92, 91, 0.9), rgba(245, 93, 44, 0.9));
}

.product-sort {
    float: right;
    margin-right: 10px;
    margin-top: 1px;
}

.ui.selection.dropdown.vchrt-dropdown {
    border: 1px solid #efefef;
    font-weight: 400;
    color: #2b2f4c;
	border-radius: 5px;
}

.ui.selection.dropdown.vchrt-dropdown:hover {
    border-color: #efefef;
}

.ui.selection.visible.dropdown.vchrt-dropdown>.text:not(.default) {
    font-weight: 400;
    color: #2b2f4c;
}

.ui.dropdown.vchrt-dropdown .menu>.item {
    color: #2b2f4c !important;
    font-weight: 400 !important;
}

.mt-30 {
	margin-top: 30px;
}

.mb-30 {
	margin-bottom: 30px;
}

.product-list-view {
    margin-top: 39px;
}

.more-product-btn {
    text-align: center;
    margin-top: 20px;
    width: 100%;
}

.show-more-btn {
    height: 40px;
    padding: 0 20px;
    border: 0;
    border-radius: 5px;
    color: #fff;
    background: #f55d2c;
    text-align: center;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
}


/* --- Single Product View --- */

.product-dt-view {
    background: #fff;
    padding: 20px 30px;
    border-radius: 10px;
	box-shadow: 0 1px 2px 0 #e9e9e9;
}

#sync1 .item {
    margin: 0px;
    text-align: center;
	cursor: pointer;
}

#sync2 .item {
    padding: 6px;
    margin: 0;
    cursor: pointer;
    background: #fff;
}

#sync1 .owl-nav .owl-prev {
	left: -20px;
    font-size: 20px !important;
    position: absolute;
    top: 45%;
    text-align: center;
    height: 30px !important;
    width: 30px !important;
    padding: 0 !important;
    color: #2b2f4c !important;
    background: #fff !important;
    border-radius: 5px !important;
    border: 1px solid #e5e5e5 !important;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

#sync1 .owl-nav .owl-next {
	font-size: 20px !important;
	position: absolute;
	right: -20px;
	top: 45%;
	padding: 0 !important;
	text-align: center;
	height: 30px !important;
	width: 30px !important;
	color: #2b2f4c !important;
	background: #fff !important;
	border-radius: 5px !important;
	border: 1px solid #e5e5e5 !important;
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

#sync1 .owl-nav .owl-prev:hover {
	color: #fff !important;
	opacity: 1;
	background: #f55d2c !important;
	border: 1px solid #f55d2c !important;
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}	

#sync1 .owl-nav .owl-next:hover {
	color: #fff !important;
	opacity: 1;
	border: 1px solid #f55d2c !important;
	background: #f55d2c !important;
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.product-dt-right {
    margin-top: 15px;
	margin-left: 20px;
}

.product-dt-right h2 {
	font-size: 24px;
    font-weight: 700;
    text-align: left;
    color: #2b2f4c;
    margin-bottom: 16px;
}

.no-stock {
    display: block;
}

.no-stock p {
    display: inline-block;
    margin-right: 30px;
    font-size: 14px;
    font-weight: 500;
    color: #2b2f4c;
}

.no-stock p:last-child {
    margin-right: 0;
}

.no-stock p span {
    margin-left: 5px;
    color: #8f91ac;
}

/* --- Single Product Radio Btn --- */

.product-radio {
    margin-top: 7px;
}

.product-now {
  list-style-type: none;
  margin:  0;
  padding: 0;
}

.product-now li {
	margin-right: 5px;
    width: 50px;
    height: 36px;
    position: relative;
    text-align: center;
    display: inline-block;
}

.product-now label,
.product-now input {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.product-now input[type="radio"] {
	display: none;
}

.product-now input[type="radio"]:checked+label,
.Checked+label {
	background: #f55d2c;
}

.product-now label {
	padding: 10px 5px;
    cursor: pointer;
    background: #c7c7c7;
    color: #fff;
    border-radius: 3px;
	font-weight: 500;
    font-size: 12px;
}

.product-now label:hover {
	background: #f55d2c;
}

.pp-descp {
    line-height: 26px;
    margin-top: 20px;
    margin-bottom: 30px;
    color: #3e3f5e;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
}

.product-group-dt {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
}

.product-group-dt ul li {
    display: inline-block;
    margin-right: 20px;
	vertical-align: middle;
}

.product-group-dt ul li:last-child {
    margin-right: 0;
}

.main-price {
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    color: #2b2f4c;
}

.main-price span {
    margin-left: 10px;
	color: #8f91ac;
}

.color-discount {
    color: #7c72ff;
}

.mrp-price {
    text-decoration: line-through;
	font-weight: 500;
}

.color-discount:after {
    content: '-';
    margin-left: 20px;
    color: #2b2f4c;
}

.gty-wish-share {
	margin-top: 22px;
}

.gty-wish-share  li {
	margin-right: 10px !important;
}

.ordr-crt-share {
	margin-top: 22px;
}

.ordr-crt-share li {
	margin-right: 10px !important;
}

span.save-icon {
	color: #f69733;
	margin-left: 10px;
	font-size: 18px;
	position: relative !important;
	cursor: pointer;
	background-color: rgba(246,151,51,0.2);
	height: 35px;
	width: 35px;
	border-radius: 30px;
	transition: all 0.4s;
}

.icon-bar {
  position: fixed;
  top: 50%;
  z-index: 2;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.icon-bar a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

.icon-bar a:hover {
  background: #f55d2c;
	color: #fff !important;
	transition: all .2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
	background-image: -webkit-linear-gradient(left, rgba(230, 92, 91, 0.9), rgba(245, 93, 44, 0.9));
    background-image: linear-gradient(to right, rgba(230, 92, 91, 0.9), rgba(245, 93, 44, 0.9));
}

.facebook {
  background: #3B5998;
  color: #fff;
  border-radius: 0 5px 0 0;
}

.twitter {
  background: #55ACEE;
  color: #fff;
}

.google {
  background: #dd4b39;
  color: #fff;
}

.linkedin {
  background: #007bb5;
  color: #fff;
}

.whatsapp {
  background: #25d366;
  color: #fff;
  border-radius: 0 0 5px 0;
}

.content {
  margin-left: 75px;
  font-size: 30px;
}

.add-cart-btn {
    background: #f55d2c;
    border: 0;
    color: #fff;
    height: 40px;
    padding: 0 20px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
	border-radius: 5px;
}

.order-btn {
    background: transparent;
    border: 1px solid #f55d2c;
    color: #f55d2c;
    height: 40px;
    padding: 0 20px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
	border-radius: 5px;
}

.add-cart-btn i {
    margin-right: 5px;
    font-size: 16px;
}

.pdp-details {
    background: #f9f9f9;
    margin-top: 20px;
    border-radius: 10px;
	float: left;
    width: 100%;
}

.pdp-details ul li {
    padding: 20px;
    border-right: 1px solid #efefef;
    width: 50%;
    display: inline-block;
	float: left;
}

.pdp-details ul li:last-child {
    border-right: 0;
}

.pdp-group-dt {
    display: flex;
	align-items: center;
    width: 100%;
}

.pdp-icon i {
    font-size: 30px;
    color: #f55d2c;
}

.pdp-text-dt {
    margin-left: 10px;
}

.pdp-text-dt span {
    font-size: 16px;
    font-weight: 500;
    color: #2b2f4c;
    margin-bottom: 10px;
    display: block;
}

.pdp-text-dt p {
    font-size: 14px;
    font-weight: 400;
    color: #3e3f5e;
    margin-bottom: 0;
    line-height: 26px;
}

.pay__logo {
    display: inline-block;
    margin: 0 1px 5px 0;
}

.pay__logo img {
    width: 20px;
}

.pdpt-bg {
    margin-top: 30px;
    background: #fff;
    border-radius: 10px;
}

.pdpt-bg {
    margin-top: 22px;
    background: #fff;
    border-radius: 10px;
	box-shadow: 0 1px 2px 0 #e9e9e9;
}

.pdpt-title h4 {
    border-bottom: 1px solid #efefef;
    padding: 15px 20px;
    font-size: 16px;
    font-weight: 600;
    color: #2b2f4c;
	margin-bottom: 0;
}

.border_radius:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.pdpt-body {
    height: 451px;
    overflow-y: auto;
}

.pdct-dts-1 {
	padding: 0 30px 30px;
}

.pdct-dt-step h4 {
    margin-top: 27px !important;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 12px;
    text-align: left;
}

.pdct-dt-step p {
    font-size: 14px;
    font-weight: 400;
    color: #3e3f5e;
    line-height: 26px;
    text-align: left;
}

.product_attr {
	font-size: 14px;
    font-weight: 400;
    color: #3e3f5e;
    line-height: 26px;
    text-align: left;
}


/* --- Checkout Page --- */

.checkout-step-body p {
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	color: #3e3f5e;
	text-align: left;
}

.phn145 {
	font-size: 14px;
	font-weight: 500;
	line-height: 24px;
	color: #3e3f5e;
}

.phn145 span {
	font-weight: 600;
	color: #f55d2c;
}

.phn145 .edit-no-btn {
	font-size: 14px;
    font-weight: 500;
    margin-left: 15px;
    display: inline-block;
    padding: 2px 10px;
    color: #fff;
    background: #f55d2c;
    border-radius: 5px;
}

.code-alrt-inputs li {
	display: inline-block;
	vertical-align: middle;
}

.code-alrt-inputs input {
	text-align: center;
}

ul.code-alrt-inputs input {
    width: 40px;
    border: 1px solid #efefef;
    height: 40px;
    text-align: center;
    padding: 0;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
}

ul.code-alrt-inputs input:focus {
    box-shadow: none;
	border: 1px solid #f55d2c;
}

label.control-label {
    font-size: 13px;
    font-weight: 500;
    color: #2b2f4c;
}

.chck-btn {
    height: 40px;
    display: inline-block;
    background: #f55d2c;
    color: #fff !important;
    padding: 0px 15px;
    font-weight: 500;
    border-radius: 5px;
    line-height: 40px;
}

.resend-link {
    margin-top: 10px;
    display: block;
    font-weight: 500;
    color: #2b2f4c;
    text-align: left;
}

.resend-link:hover {
    color: #f55d2c;
}

.checout-address-step .form-control {
    font-size: 14px;
	height: 40px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: #2b2f4c;
    border: 1px solid #efefef;
	position: relative;
	padding: 0 1rem 0 1rem;
	border-radius: 5px !important;
}

.form-control:focus {
    outline: none !important;
    box-shadow: none !important;
	border: 1px solid #f55d2c !important;
}

.address-btns {
    display: flex;
    align-items: end;
}

.save-btn14 {
    height: 40px;
    line-height: 38px;
    padding: 0 30px;
    border: 1px solid;
    background: transparent;
    color: #f55d2c;
    border-radius: 5px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}

.next-btn16 {
    height: 40px;
    line-height: 38px;
    padding: 0px 30px;
    border: 1px solid #f55d2c;
    background: #f55d2c;
    color: #fff;
    border-radius: 5px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    display: inline-block;
}

.time-radio {
    margin-top: 10px;
}

.date-slider-group {
    margin-top: 10px;
}

.date-slider-group {
  list-style-type: none;
  margin:  0;
  padding: 0;
}

.date-slider-group .date-now {
	margin-right: 5px;
    width: 100%;
    height: 36px;
    position: relative;
    text-align: center;
    display: inline-block;
}

.date-slider-group label,
.date-slider-group input {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.date-slider-group input[type="radio"] {
	display: none;
}

.date-slider-group input[type="radio"]:checked+label,
.Checked+label {
	background: #f55d2c;
}

.date-slider-group label {
    padding: 9px;
    cursor: pointer;
    background: #2b2f4c;
    color: #fff;
    border-radius: 5px;
    font-weight: 500;
    font-size: 12px;
}

.date-slider-group label:hover {
	background: #f55d2c;
}

.form-check-input:checked {
    background-color: #f55d2c !important;
    border-color: #f55d2c !important;
	box-shadow: none !important;
}

.ui.checkbox.chck-rdio label {
    color: #2b2f4c !important;
	font-weight: 400 !important;
    font-family: 'Roboto', sans-serif !important;
}

.ui.radio.checkbox.chck-rdio .box:after, .ui.radio.checkbox.chck-rdio label:after {
    background-color: #f55d2c !important;
}

.total-checkout-group {
    padding: 20px 20px 0;
	border-top: 1px solid #efefef;
    margin-top: 10px;
}

.payment-secure {
    text-align: center;
    padding: 20px;
    font-weight: 500;
    font-size: 14px;
    color: #2b2f4c;
}

.checkout-safety-alerts {
    background: #fff;
    border-radius: 10px;
    margin-top: 30px;
	box-shadow: 0 1px 2px 0 #e9e9e9;
}

.checkout-safety-alerts p {
    margin-bottom: 0;
    padding: 15px;
    border-bottom: 1px solid #efefef;
    font-size: 14px;
    font-weight: 500;
    color: #3e3f5e;
    text-align: left;
}

.checkout-safety-alerts p:last-child {
    border-bottom: 0;
}

.checkout-safety-alerts i {
    margin-right: 5px;
}

.return-departure-dts {
    color: #727272;
    font-weight: 400;
    font-size: 14px;
    position: relative;
    margin-top: 0;
    display: none;
	margin-bottom: 25px !important;
}

.single-payment-method:first-child .payment-method-details {
    display: block;
}

.radio--group-inline-container_1 {
    width: 100%;
    width: 100%;
    display: block;
    margin-bottom: 20px !important;
}

.radio--group-inline-container_1 li {
    display: inline-block;
    width: 33.333%;
	margin-right: 10px;
	margin-bottom: 10px !important;
}

.radio--group-inline-container_1 li:last-child {
    margin-right: 0;
}

.radio--group-inline-container_1 .radio-item_1 {
    display: inline-block;
	margin-right: 10px;
	width: 100%;
}

.radio--group-inline-container_1 .radio-item_1 input[type="radio"] {
	position: absolute;
	opacity: 0;
}

.radio--group-inline-container_1 .radio-item_1 input[type="radio"] + .radio-label_1:before {
	content: '';
	background: #f7f7f7;
	border-radius: 100%;
	border: 1px solid #b4b4b4;
	display: inline-block;
	width: 1em;
	height: 1em;
	top: 0px;
	position: relative;
	margin-right: 10px;
	vertical-align: top;
	cursor: pointer;
	text-align: center;
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
}

.radio--group-inline-container_1 .radio-item_1 input[type="radio"]:checked + .radio-label_1:before {
	background-color: #f55d2c;
	box-shadow: inset 0 0 0 2px #f4f4f4;
}

.radio--group-inline-container_1 .radio-item_1 input[type="radio"]:focus + .radio-label_1:before {
	outline: none;
	border-color: #0E8A86;
}

.radio--group-inline-container_1 .radio-item_1 input[type="radio"]:disabled + .radio-label_1:before {
	box-shadow: inset 0 0 0 4px #f4f4f4;
	border-color: #b4b4b4;
	background: #b4b4b4;
}

.radio--group-inline-container_1 .radio-item_1 input[type="radio"] + .radio-label:empty:before {
	margin-right: 0;
}

.radio-label_1 {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    line-height: 16px;
	color: #2b2f4c;
	cursor: pointer;
	padding: 15px 20px;
    background: #f9f9f9;
    width: 100%;
	border-radius: 5px;
	border: 1px solid #efefef;
}

.radio-label_1:hover {
	background: #f5f5f5;
    transition: all .4s ease-in-out;
}

.pymnt_title h4 {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 15px;
    color: #2b2f4c;
}

.swdh19 {
    width: 100%;
}

textarea.form-control {
    min-height: calc(1.5em + 0.75rem + 2px);
    height: 110px;
    border-radius: 5px;
}

.ui[class*="left icon"].swdh19.input>input {
    padding-left: 1em!important;
    padding-right: 1em!important;
    height: 40px;
    border-radius: 5px !important;
}

.ui.input>input {
	font-size: 14px;
	font-weight: 400 !important;
    background: #fff;
    border: 1px solid #efefef !important;
    color: #2b2f4c !important;
    border-radius: 5px !important;
}

.h_50 {
	height: 50px !important;
	line-height: 50px !important;
}

.ui.input>input:focus {
    border: 1px solid #f55d2c !important;
}

.ui.selection.active.dropdown.form-dropdown {
    border-color: #f55d2c !important;
}

.ui.selection.active.dropdown.form-dropdown .menu {
    border-color: #f55d2c !important;
}

.ui.dropdown.form-dropdown .menu>.item {
    color: #2b2f4c !important;
    font-size: 14px !important; 
    font-weight: 400 !important;
}

.ui.selection.dropdown.form-dropdown:hover {
    border-color: #efefef !important;
}

.ui.selection.dropdown.form-dropdown {
    color: #2b2f4c !important;
	font-weight: 500 !important;
    border: 1px solid #efefef !important;
}

.ui.selection.visible.dropdown.form-dropdown>.text:not(.default) {
    font-weight: 500 !important;
    color: #2b2f4c !important;
}

.promo-link45 {
	margin-top: 30px;
    background: #fff;
    border-radius: 10px;
    padding: 12px 20px;
    text-align: center;
	font-size: 14px;
    font-weight: 500;
    color: #2b2f4c;
    line-height: 24px;
	display: block;
	box-shadow: 0 1px 2px 0 #e9e9e9;
}

.promo-link45:hover {
    color: #f55d2c !important;

}

/* --- Order Placed --- */

.order-placed-dt {
    text-align: center;
    padding: 20px 0 14px;
    float: left;
    width: 100%;
}

.order-placed-dt .icon-circle {
    font-size: 56px;
    color: #f55d2c;
}
.order-placed-dt h2 {
    font-size: 30px;
    font-weight: 500;
    color: #2b2f4c;
    text-align: center;
    margin-bottom: 25px;
}

.order-placed-dt p {
    font-size: 16px;
    font-weight: 500;
    color: #3e3f5e;
    margin-bottom: 0;
    text-align: center;
	line-height: 24px;
}

.delivery-address-bg {
    margin-top: 40px;
    background: #fff;
    border-radius: 5px;
    float: left;
    width: 100%;
	text-align: left;
	box-shadow: 0 1px 2px 0 #e9e9e9;
}

.title585 {
    display: inline-block;
    float: left;
    width: 100%;
	border-bottom: 1px solid #efefef;
}

.pln-icon {
    float: left;
    width: 50px;
    height: 50px;
    text-align: center;
	line-height: 50px;
    margin-right: 10px;
}

.title585 h4 {
    float: left;
    margin-top: 0;
    font-weight: 500;
    font-size: 16px;
    color: #2b2f4c;
	line-height: 50px;
}

.pln-icon i {
    font-size: 20px;
	color: #f55d2c;
}

.address-placed-dt1 {
    float: left;
    padding: 20px !important;
	width: 100%;
}

.address-placed-dt1 li {
    margin-bottom: 10px;
}

.address-placed-dt1 li:last-child {
    margin-bottom: 0;
}

.address-placed-dt1 i {
    margin-right: 5px;
}

.address-placed-dt1 p {
    font-size: 14px;
    font-weight: 500;
	text-align: left;
	color: #2b2f4c;
}

.address-placed-dt1 p span {
    margin-left: 10px;
	font-weight: 400;
	color: #3e3f5e;
}

.stay-invoice {
    float: left;
    width: 100%;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
}

.invc-link {
    margin-left: auto;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    background: #f55d2c;
    padding: 5px 15px;
    border-radius: 5px;
}

.st-hm {
    font-size: 16px;
    font-weight: 500;
    color: #2b2f4c;
}

.st-hm i {
    margin-left: 5px;
}

.placed-bottom-dt {
    padding: 20px;
    float: left;
    font-size: 14px;
    font-weight: 400;
    color: #3e3f5e;
    margin-bottom: 0;
    text-align: center;
    line-height: 24px;
    width: 100%;
}

.placed-bottom-dt span {
    font-weight: 600;
    color: #f55d2c;
}

/* --- User Dashboard Page --- */

.dashboard-group {
    background: rgba(245,93,44, 0.3);
    position: relative;
    padding: 50px 0;
}

.dashboard-group:before {
    content: "";
    /*background: url(../images/banner.svg) no-repeat bottom;*/
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    pointer-events: none;
    background-size: cover;
    opacity: 0.02;
}

.user-dt {
    position: relative;
    width: 100%;
    text-align: center;
}

.user-img {
    position: relative;
	display: inline-block;
}

.user-img img {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    border: 2px solid #fff;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
}

.user-img .change-btn {
    position: absolute;
    bottom: 5px;
    right: -5px;
    background: #f55d2c;
    color: #fff;
    height: 35px;
    width: 35px;
    border-radius: 100%;
	border: 1px solid #ffff;
    font-size: 18px;
    padding: 8px 5px;
}

.img-add {
	display: block;
}

.img-add [type="file"] {
	height: 0;
	overflow: hidden;
	width: 0;
	float: left;
}

.img-add [type="file"] + label {
	cursor: pointer;
	display: inline-block;
	outline: none;
	transition: all 0.3s;
	vertical-align: middle;
	margin: 0;
	position: absolute;
    bottom: 5px;
    right: -5px;
    background: #f55d2c;
    color: #fff;
    height: 35px;
    width: 35px;
    border-radius: 100%;
	border: 1px solid #ffff;
    font-size: 18px;
    padding: 8px 5px;
}

.img-add [type="file"] + label:hover {
	background: #f55d2c;
	color: #fff !important;
	transition: all .2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
	background-image: -webkit-linear-gradient(left, rgba(230, 92, 91, 0.9), rgba(245, 93, 44, 0.9));
    background-image: linear-gradient(to right, rgba(230, 92, 91, 0.9), rgba(245, 93, 44, 0.9));
}

.user-dt h4 {
    font-size: 24px;
    color: #2b2f4c;
    text-align: center;
    margin-bottom: 8px;
    margin-top: 18px;
}

.user-dt p {
    font-size: 14px;
    color: #3e3f5e;
    text-align: center;
	margin-bottom: 20px;
	font-weight: 500;
	display: block;
}

.user-dt p a {
	color: #3e3f5e !important;
    font-size: 16px;
	margin-left: 5px;
}

.user-dt p a:hover {
    color: #f55d2c !important;
}

.earn-points img {
    width: 24px;
    margin-right: 7px;
}

.earn-points {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    color: #2b2f4c;
    background: rgba(255,255,255, 0.3);
	padding: 5px 11px 5px 10px;
    border-radius: 5px;
}


.left-side-tabs {
    position: sticky;
    top: 167px;
}

.left-side-tabs {
    background: #fff;
    margin-top: 64px;
    border-radius: 10px;
	box-shadow: 0 1px 2px 0 #e9e9e9;
}

.user-item {
    display: flex;
    padding: 12px 20px;
    border-bottom: 1px solid #efefef;
    font-size: 14px;
    font-weight: 400;
    color: #2b2f4c;
    text-align: left;
    align-items: center;
}

.user-item:last-child {
    border-bottom: 0;
}

.user-item i {
    margin-right: 10px;
	font-size: 18px;
}

.user-item:hover { 
	color: #2b2f4c;
    background:#ffecec;
}

.user-item:first-child:hover { 
	border-radius: 10px 10px 0 0;
}

.user-item:last-child:hover { 
	border-radius: 0 0 10px 10px;
}

.user-item:hover i { 
	color: #f55d2c !important;
}

.user-item.active { 
	background: #ca1515;
	color: #fff !important;
	transition: all .2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
	/* background-image: -webkit-linear-gradient(left, rgba(230, 92, 91, 0.9), rgba(245, 93, 44, 0.9));
    background-image: linear-gradient(to right, rgba(230, 92, 91, 0.9), rgba(245, 93, 44, 0.9));; */
	pointer-events:none;
}

.user-item:first-child.active { 
	border-radius: 10px 10px 0 0;
}

.user-item:last-child.active{ 
	border-radius: 0 0 10px 10px;
}

.dashboard-right {
    margin-top: 64px;
}

.main-title-tab h4 {
    font-size: 18px;
    font-weight: 700;
    color: #2b2f4c;
    text-align: left;
    margin-bottom: 0;
    margin-top: 0;
}

.main-title-tab i {
    margin-right: 5px;
}

.welcome-text h2 {
    font-size: 24px;
    font-weight: 500;
    color: #2b2f4c;
    text-align: left;
    margin-bottom: 3px !important;
    margin-top: 30px !important;
}

.ddsh-body {
    padding: 1.5rem;
}

.ddsh-body h2 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: left;
    color: #2b2f4c;
	font-weight: 500;
}

.more-link14 {
    text-align: left;
    padding: 20px;
    font-weight: 500;
    font-size: 14px;
    color: #2b2f4c;
	display: block;
	border-top: 1px solid #efefef;
}

.more-link14:hover {
    color: #f55d2c !important;
}

.ddsh-body ul li {
    display: inline-block;
    margin-right: 10px;
}

.ddsh-body ul li:last-child {
    margin-right: 0;
}

.small-reward-dt {
    color: #fff;
    background: #f55d2c;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 12px;
    display: block;
	margin-bottom: 7px;
}

.rewards-link5 {
    font-size: 14px;
    font-weight: 500;
    color: #2b2f4c;
}

.rewards-link5:hover {
    color: #f55d2c !important;
}

.smll-history {
    display: flex;
    width: 100%;
	padding: 4px 0;
}

.order-title {
    font-size: 14px;
    font-weight: 500;
    color: #2b2f4c;
}

.order-title span {
    font-size: 12px;
    display: inline-block;
    background: #2b2f4c;
    color: #fff;
    height: 18px;
    border-radius: 100%;
    width: 18px;
    text-align: center;
    font-weight: 700;
    margin-left: 5px;
    cursor: pointer;
}

.order-title span:hover {
    background: #f55d2c;
}

.smll-history p {
    margin-left: auto;
	font-size: 14px;
    font-weight: 600;
    color: #3e3f5e;
	margin-bottom: 0;
}

.order-list-145 li {
    display: block !important;
    margin-right: 0 !important;
}

.wllt-body h2 {
	font-weight: 500;
}

.order-status {
    margin-left: auto;
    font-size: 14px;
    font-weight: 500;
    color: #f55d2c;
}

[data-inverted][data-position~=top][data-tooltip]:before {
    background: #2b2f4c !important;
}

[data-position="top right"][data-tooltip]:before {
    right: 0.1em !important;
}

[data-tooltip][data-inverted]:after {
    background: #2b2f4c !important;
    color: #fff;
}

[data-tooltip]:after {
    border: 1px solid #d4d4d5;
    font-size: 14px !important;
	font-weight: 600 !important;
	font-family: 'Roboto', sans-serif;
}

.wllt-body {
    padding: 20px 20px 0;
}

.wallet-list {
    padding-top: 10px;
}

.wallet-list li {
	width: 50% !important;
    display: inline-block;
    margin-right: 0 !important;
	margin-bottom: 20px;
    margin-left: -2px;
}

.wallet-links14 {
	font-size: 14px;
    font-weight: 500;
    color: #2b2f4c;
	display: flex;
	align-items: center;
}

.wallet-links14 i {
	font-size: 18px;
	margin-right: 5px;
}

.newsletter-input {
	position: relative;
}

.newsletter-btn {
     background: #f55d2c;
    position: absolute;
    right: 3px;
    top: 2px;
    height: 35px;
    border: 0;
    width: 35px;
    border-radius: 5px;
    font-size: 18px;
    color: #fff;
}

.newsletter-input .form-control {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: #a2d5c6 !important;
    border: 1px solid #3f4464;
    position: relative;
    padding: 0 3rem 0 1rem;
    height: 40px;
    border-radius: 5px !important;
    background: #1d2035;
}

.newsletter-input .form-control:focus {
    border: 1px solid #f55d2c !important;
    border-radius: 5px !important;
    background: #1d2035 !important;
}

/* --- Dashboard My Orders --- */

.pdpt-title h6 {
    border-bottom: 1px solid #efefef;
    padding: 15px 20px;
    font-size: 14px;
    font-weight: 400;
    color: #2b2f4c;
	margin-bottom: 0;
}

.order-body10 {

}

.order-dtsll {
	padding: 20px !important;
}

.order-dtsll li {
    display: inline-block;
	vertical-align: top;
}

.order-dt-img {
    background: #f9f9f9;
    padding: 10px;
    border: 1px solid #efefef;
    border-radius: 5px;
}

.order-dt-img img {
    width: 50px;
}

.order-dt47 {
    margin-left: 15px;
}

.order-dt47 h4 {
    font-size: 16px;
    color: #2b2f4c;
    margin-bottom: 5px;
    line-height: 24px;
    text-align: left;
	font-weight: 700;
}

.order-dt47 p {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
	color: #3e3f5e;
	margin-bottom: 7px;
}

.track-order {
    padding: 20px 0 20px;
}

.track-order h4 {
    margin-left: 20px;
    margin-bottom: 20px;
    font-weight: 700;
    color: #2b2f4c;
    text-align: left;
}

.bs-wizard {
	margin-top: 0;
    display: flex;
    width: 100%;	
}

/*Form Wizard*/
.bs-wizard {
	border-bottom: solid 1px #e0e0e0;
	padding: 0;
}

.bs-wizard > .bs-wizard-step {
	padding: 0; 
	position: relative; 
	width: 100%;
}

.bs-wizard > .bs-wizard-step + .bs-wizard-step {}

.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {
	color: #2b2f4c;
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 400;
}

.bs-wizard > .bs-wizard-step .bs-wizard-info {
	color: #999; 
	font-size: 14px;
}

.bs-wizard > .bs-wizard-step > .bs-wizard-dot {
	position: absolute; 
	width: 30px; 
	height: 30px; 
	display: block; 
	background: #ffcfc0; 
	top: 45px; 
	left: 50%; 
	margin-top: -15px; 
	margin-left: -15px; 
	border-radius: 50%;
} 

.bs-wizard > .bs-wizard-step > .bs-wizard-dot:after {
	content: ' '; 
	width: 14px; 
	height: 14px; 
	background: #f55d2c;
	border-radius: 50px; 
	position: absolute; 
	top: 8px; 
	left: 8px; 
} 

.bs-wizard > .bs-wizard-step > .progress {
	position: relative;
	border-radius: 0px;
	height: 8px; 
	box-shadow: none; 
	margin: 21px 0;
}

.bs-wizard > .bs-wizard-step > .progress > .progress-bar {
	width:0px; 
	box-shadow: none; 
	background: #ffcfc0;
}

.bs-wizard > .bs-wizard-step.complete > .progress > .progress-bar {
	width:100%;
}

.bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {
	width:50%;
}

.bs-wizard > .bs-wizard-step:first-child.active > .progress > .progress-bar {
	width:0%;
}

.bs-wizard > .bs-wizard-step:last-child.active > .progress > .progress-bar {
	width: 100%;
}

.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot {
	background-color: #efefef;
}

.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot:after {
	opacity: 0;
}

.bs-wizard > .bs-wizard-step:first-child  > .progress {
	left: 50%; 
	width: 50%;
}

.bs-wizard > .bs-wizard-step:last-child  > .progress {
	width: 50%;
}

.bs-wizard > .bs-wizard-step.disabled a.bs-wizard-dot{ 
	pointer-events: none; 
}

.progress {
    background-color: #efefef !important;
}
/*END Form Wizard*/


.alert-offer img {
    width: 30px;
	margin-right: 10px;
}

.alert-offer {
    padding: 20px;
    border-top: 1px solid #efefef;
    font-weight: 400;
    color: #3e3f5e;
    text-align: left;
    line-height: 24px;
}

.call-bill {
    padding: 15px 20px;
    display: flex;
    border-top: 1px solid #efefef;
    align-items: center;
}

.delivery-man {
    font-size: 14px;
    color: #2b2f4c;
    font-weight: 400;
    text-align: left;
    line-height: 24px;
}

.delivery-man a {
    margin-left: 5px;
    color: #f55d2c;
}

.delivery-man a:hover {
    color: #f55d2c !important;
	text-decoration: underline !important;
}

.order-bill-slip {
    margin-left: auto;
}

.bill-btn5 {
    display: block;
    background: #f55d2c;
    color: #fff;
    padding: 10px 15px;
    font-weight: 500;
    border-radius: 5px;
}

/* --- My Rewards --- */

.reward-body-dtt {
    padding: 30px;
	text-align: center;
}

.reward-img-icon {
    width: 80px;
    height: 80px;
    display: inline-block;
    background: #f9f9f9;
    border-radius: 100%;
    border: 2px solid #efefef;
	padding: 18px 0;
}

.reward-img-icon img {
    width: 40px;
    text-align: center;
}

.rewrd-title {
    display: block;
    margin-top: 20px;
    font-weight: 500;
    color: #3e3f5e;
    font-size: 14px;
    text-align: center;
}

.cashbk-price {
    color: #2b2f4c;
    margin-top: 12px;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 0;
    text-align: center;
}

.reward-body-all {
    display: flex;
	text-align: center;
}

.reward-body-all li {
    display: inline-block;
    width: 33.333%;
	padding: 30px 10px;
	border-right: 1px solid #efefef;
}

.reward-body-all li:last-child {
	border-right: 0;
}

.tt-icon {
    width: 45px;
    height: 45px;
    display: inline-block;
    background: #f55d2c;
    border-radius: 100%;
    padding: 5px 0;
}

.tt-icon i {
    color: #fff;
    font-size: 24px;
}

.total-rewards span {
    display: block;
    margin-top: 20px;
    font-weight: 600;
    color: #3e3f5e;
    font-size: 14px;
    text-align: center;
}

.total-rewards h4 {
    color: #2b2f4c;
    margin-top: 12px;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 0;
    text-align: center;
}

.date-reward {
    display: inline-block;
    margin-top: 20px;
    font-weight: 500;
    background: #f9f9f9;
    border: 1px dashed #efefef;
    padding: 2px 15px;
    border-radius: 5px;
    font-size: 12px;
    color: #2b2f4c;
}

.rewards-coupns {
	position: relative;
}

.top-coup-code {
    position: absolute;
    top: 0;
    right: 0;
    padding: 3px 10px;
    font-weight: 500;
    font-size: 12px;
    color: #fff;
    background: #f55d2c;
    border-radius: 0 5px 0 3px;
	cursor: pointer;
    border: 1px dashed #fff;
}

.gambo-body-cash {
    padding: 30px 20px;
    text-align: center;
}

.gambo-body-cash p {
    font-size: 14px;
    font-weight: 500;
    color: #3e3f5e;
    margin-top: 20px;
    line-height: 24px;
	margin-bottom: 0;
}

.rotate-img {
    transform: rotate(260deg);
}

.table-responsive {
    border: 0;
    border-radius: 0 0 10px 10px;
    margin-bottom: 0;
    overflow-x: inherit;
}

table.table.ucp-table {
    margin-bottom: 0px;
}

.ucp-table {
    height: auto;
    overflow: hidden;
    border-radius: 0;
}

.ucp-table thead {
    font-weight: 500;
    padding: 14px !important;
    border-radius: 0;
    color: #2b2f4c !important;
}


.ucp-table thead tr th:first-child {
    border-bottom-left-radius: 0;
}

.ucp-table thead tr th {
    color: #2b2f4c;
	font-size: 14px;
    background-color: #ffecec;
	font-weight: 500;
}

.ucp-table tfoot {
    font-weight: 400;
    padding: 14px !important;
    border-radius: 4px;
    font-family: 'Roboto', sans-serif;
    color: #2b2f4c !important;
}

.ucp-table tfoot td {
    color: #fff;
    font-size: 14px;
    background-color: #2b2f4c;
	padding: .75rem !important;
}

.ucp-table.earning__table td {
	padding: 1rem !important;
}

table {
    margin: 0px auto 0px;
    font-size: 14px;
    width: 100%;
}

.ucp-table tbody {
    background: #fff;
}

.ucp-table td, .ucp-table th {
    border-top: 1px solid #efefef !important;
}

.ucp-table td {
    padding: 1.5rem .75rem !important;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.ucp-table th:first-child {
   
}

.table>:not(:first-child) {
    border-top: 0 !important;
}

.ucp-table thead th {
    vertical-align: bottom;
    border-bottom: 0 !important;
}

.course_active {
    color: #ed2a26;
}

.ucp-table tbody tr td {
    font-size: 14px;
    vertical-align: middle;
	font-weight: 400;
	color: #3e3f5e;
}

.offer_active {
    color: #f55d2c;
	font-weight: 700;
}

.add-cash-body {
    padding: 1.5rem;
}


.history-body {
	height: 364px;
    overflow-y: auto;
}

.history-list li {
    display: block;
    padding: 1.5rem;
    border-bottom: 1px solid #efefef;
}

.history-list li:last-child {
    border-bottom: 0;
}

.purchase-history {
    display: flex;
    align-items: center;
}

.purchase-history-right {
    margin-left: auto;
}

.purchase-history-left h4 {
    font-size: 16px;
    color: #2b2f4c;
    margin-bottom: 8px;
    text-align: left;
	font-weight: 500;
}

.purchase-history-left p {
    font-size: 14px;
    font-weight: 500;
    color: #3e3f5e;
    text-align: left;
    margin-bottom: 8px;
    line-height: 24px;
}

.purchase-history-left p ins {
    text-decoration: none;
	text-transform: uppercase;
	color: #f55d2c;
}

.purchase-history-left span {
    font-weight: 400;
    font-size: 13px;
    color: #3e3f5e;
    text-align: left;
    display: block;
}

.purchase-history-right span {
    display: block;
    font-size: 16px;
    font-weight: 600;
    color: #f55d2c;
    text-align: center;
}

.purchase-history-right {
    text-align: center;
}

.purchase-history-right a {
    font-size: 14px;
    font-weight: 500;
    margin-top: 9px;
    display: block;
    color: #2b2f4c;
}

.purchase-history-right a:hover {
    color: #f55d2c !important;
}

/* --- My Addresses --- */

.add-address {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 20px;
    margin-left: 20px;
    color: #fff;
    background: #f55d2c;
    border-radius: 5px;
    font-weight: 500;
	margin-bottom: 10px;
}

.address-item {
    padding: 20px;
    border-bottom: 1px solid #efefef;
}

.address-item:last-child {
    border-bottom: 0;
}

.address-item {
    display: flex;
    align-items: self-start;
}

.address-icon1 {
    margin-right: 10px;
    background: #f55d2c;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
}

.address-icon1 i {
    font-size: 16px;
}

.address-dt-all h4 {
    color: #2b2f4c;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    margin-top: 0;
    margin-bottom: 8px;
}

.address-dt-all p {
    font-size: 14px;
    font-weight: 400;
    color: #3e3f5e;
    margin-bottom: 0;
    line-height: 24px;
}

.action-btns {
    margin-top: 10px;
}

.action-btns li {
    display: inline-block;
    margin-right: 5px;
}

.action-btns li:last-child {
    margin-right: 0;
}

.action-btn {
    font-size: 16px;
    color: #3e3f5e;
}

.action-btn:hover {
    color: #f55d2c !important;
}

.add-address-form {
    padding: 20px 30px 30px;
}


/* --- Career Page --- */

.default-dt {
    padding: 50px;
    background: #2b2f4c;
    position: relative;
}

.default-dt:before {
    content: "";
    /*background: url(../images/career/default.jpg) no-repeat center;*/
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    pointer-events: none;
    background-size: cover;
    opacity: 0.1;
}

.default_tabs {
	margin-top: 0;
}

.default_tabs .nav-item {
    margin-bottom:  0 !important;
}

.default_tabs .nav-tabs .nav-item.show .nav-link, .default_tabs .nav-tabs .nav-link.active {
    background: #f55d2c;
	color: #fff !important;
	transition: all .2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
	background-image: -webkit-linear-gradient(left, rgba(230, 92, 91, 0.9), rgba(245, 93, 44, 0.9));
    background-image: linear-gradient(to right, rgba(230, 92, 91, 0.9), rgba(245, 93, 44, 0.9));
    border-color: inherit !important;
    border-top: 0 !important;
    border-bottom: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-radius: 5px !important;
}

.default_tabs .nav-tabs {
    border-bottom: 0 !important;
}

.tab_default .nav-link {
    border-top: 0 !important;
    border-bottom: 2px solid transparent !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
	color: rgba(255,255,255,0.7)!important;
	font-weight: 500 !important;
    font-family: 'Roboto', sans-serif !important;
}

.tab_default .nav-link {
    padding: .5rem 1.5rem !important;
}

.title129 {
    text-align: center;
}

.title129 h2 {
    font-size: 24px;
    font-weight: 700;
    margin-top: 58px !important;
    color: #fff;
    text-align: center;
    line-height: 30px;
}

.position-link-btn {
    display: inline-block;
    padding: 10px 20px;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
    margin-top: 21px;
    font-weight: 500;
    font-size: 14px;
}

.life-gambo {
    padding: 59px 0;
}

.default-title {
    text-align: center;
	padding-bottom: 8px;
}

.default-title h2 {
    font-size: 24px;
    color: #2b2f4c;
    font-weight: 700;
    margin-bottom: 13px;
}

.default-title p {
    line-height: 24px;
    font-weight: 400;
    color: #3e3f5e;
    font-size: 14px;
    margin-bottom: 15px;
}

.dd-content {
	margin-top: 30px;
	width: 100%;
}

.life-slider .owl-nav .owl-prev {
	left: -20px;
    font-size: 20px !important;
    position: absolute;
    top: 42%;
    text-align: center;
    height: 30px !important;
    width: 30px !important;
    padding: 4px 0 !important;
    color: #2b2f4c !important;
    background: #fff !important;
    border-radius: 100% !important;
    border: 1px solid #e5e5e5 !important;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.life-slider .owl-nav .owl-next {
	font-size: 20px !important;
	position: absolute;
	right: -20px;
	top: 42%;
	padding: 4px 0 !important;
	text-align: center;
	height: 30px !important;
	width: 30px !important;
	color: #2b2f4c !important;
	background: #fff !important;
	border-radius: 100% !important;
	border: 1px solid #e5e5e5 !important;
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.life-slider .owl-nav .owl-prev:hover {
	color: #fff !important;
	opacity: 1;
	background: #f55d2c !important;
	border: 1px solid #f55d2c !important;
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}	

.life-slider .owl-nav .owl-next:hover {
	color: #fff !important;
	opacity: 1;
	border: 1px solid #f55d2c !important;
	background: #f55d2c !important;
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.white-bg {
	background: #fff;
}

.benefits-step {
    text-align: center;
    margin-bottom: 65px;
}

.benefit-icon {
    display: inline-block;
    width: 70px;
    height: 70px;
    text-align: center;
    background: #f55d2c;
    border-radius: 100%;
}

.benefit-link {
    display: block;
    margin-top: 20px;
    font-size: 18px;
    font-weight: 500;
    color: #2b2f4c;
    line-height: 24px;
    text-align: center;
}

.benefit-link:hover {
    color: #2b2f4c !important;
}

.benefit-icon i {
    font-size: 30px;
    color: #fff;
    padding: 20px 0;
}

.know-more-link {
    text-align: center;
}

.kmore-btn {
    background: #f55d2c;
    color: #fff;
    display: inline-block;
    padding: 10px 20px;
    font-weight: 600;
    border-radius: 5px;
    text-align: center;
	cursor: pointer;
}

.testi-item {
    background: #fff;
    padding: 30px 20px;
	border-radius: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.qoute-icon {
    height: 50px;
    text-align: center;
    font-size: 24px;
    color: #f55d2c;
}

.testo-text {
    text-align: center;
    line-height: 26px;
    font-weight: 400;
    color: #3e3f5e;
}

.team-dt {
    margin-top: 35px;
    text-align: center;
}

.team-avatar img {
    width: 50px !important;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #fff;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
}

.team-avatar {
    display: inline-block;
}

.team-emp-dt h4 {
    font-size: 18px;
    font-weight: 600;
    margin-top: 18px !important;
    text-align: center;
    margin-bottom: 10px;
    color: #2b2f4c;
}

.team-emp-dt p {
    font-size: 14px;
    font-weight: 400;
    color: #3e3f5e;
    margin-bottom: 0;
    text-align: center;
    line-height: 26px;
}

.testimonial-slider .owl-nav .owl-prev {
	left: -20px;
    font-size: 20px !important;
    position: absolute;
    top: 45%;
    text-align: center;
    height: 30px !important;
    width: 30px !important;
    padding: 0 !important;
    color: #2b2f4c !important;
    background: #fff !important;
    border-radius: 5px !important;
    border: 1px solid #e5e5e5 !important;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.testimonial-slider .owl-nav .owl-next {
	font-size: 20px !important;
	position: absolute;
	right: -20px;
	top: 45%;
	padding: 0 !important;
	text-align: center;
	height: 30px !important;
	width: 30px !important;
	color: #2b2f4c !important;
	background: #fff !important;
	border-radius: 5px !important;
	border: 1px solid #e5e5e5 !important;
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.testimonial-slider .owl-nav .owl-prev:hover {
	color: #fff !important;
	opacity: 1;
	background: #f55d2c !important;
	border: 1px solid #f55d2c !important;
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}	

.testimonial-slider .owl-nav .owl-next:hover {
	color: #fff !important;
	opacity: 1;
	border: 1px solid #f55d2c !important;
	background: #f55d2c !important;
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.jobs-gambo {
    padding: 0;
}

.pd-20 {
    padding: 20px;
}

.job-title-group {
    display: flex;
    align-items: center;
	margin-bottom: 10px;
}

.job-title-group h4 {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 0;
    color: #2b2f4c;
}

.flter-clear-link {
    margin-left: auto;
    font-weight: 600;
    font-size: 14px;
    color: #3e3f5e;
}

.flter-clear-link:hover {
    color: #f55d2c !important;
}

.all-departments {
    margin-top: 21px;
}

.job-bg1 {
    margin-top: 20px;
    background: #fff;
    border-radius: 10px;
    padding: 25px 30px;
    box-shadow: 0 1px 2px 0 #e9e9e9;
    display: flex;
    width: 100%;
    align-items: center;
}

.job-bg1:first-child {
    margin-top: 30px;
}

.job-item h4 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 10px;
    color: #2b2f4c;
}

.job-item span {
    font-size: 14px;
    font-weight: 400;
    color: #3e3f5e;
    line-height: 24px;
    text-align: left;
}

.job-item span i {
    margin-right: 3px;
}

.arrow-icon {
    font-size: 24px;
    color: #2b2f4c;
    margin-left: auto;
}

.job-main-dt {
    padding-bottom: 25px;
    border-bottom: 1px solid #efefef;
}

.job-main-dt h2 {
    color: #2b2f4c;
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    margin-top: 0 !important;
    margin-bottom: 12px;
}

.job-main-dt span {
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    line-height: 24px;
    color: #3e3f5e;
}

.job-des-dt142 h4 {
    color: #2b2f4c;
	font-weight: 600;
    font-size: 16px;
    text-align: left;
    margin-bottom: 12px;
    margin-top: 21px !important;
}

.job-des-dt142 p {
    color: #3e3f5e;
    font-size: 14px;
	font-weight: 400;
    text-align: left;
    margin-bottom: 0;
	line-height: 26px;
}

.joby-list-dt {
    list-style: disc;
}

.joby-list-dt li {
    margin-left: 30px;
    margin-bottom: 4px;
}

.joby-list-dt li:last-child {
    margin-bottom: 0;
}

.job-req-dt {
    padding-bottom: 25px;
    border-bottom: 1px solid #efefef;
    margin-top: 30px;
    padding-top: 25px;
    border-top: 1px solid #efefef;
}

.job-req-dt h3 {
    color: #2b2f4c;
    font-size: 18px;
    text-align: left;
	font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
}

.apply-btn {
	background: #f55d2c;
    color: #fff;
    padding: 0 20px;
	height: 40px;
    font-weight: 500;
    border-radius: 5px;
    text-align: center;
	font-family: 'Roboto', sans-serif;
	border: 0;
	margin-top: 34px;
	margin-bottom: 14px;
}

textarea {
    overflow: auto;
	padding: 10px 15px !important;
}

.ques-142 {
    margin-top: 1.5rem !important;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0 !important;
    padding-bottom: 15px;
    border-bottom: 1px solid #efefef;
    color: #2b2f4c;
}

.ui.checkbox label {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #3e3f5e !important;
}

.ui.checkbox input:checked~.box:after, .ui.checkbox input:checked~label:after {
    color: #f55d2c !important;
}

.ui.checkbox input:checked~.box:before, .ui.checkbox input:checked~label:before {
    border-color: #f55d2c !important;
}

.ui.checkbox .box:after, .ui.checkbox label:after {
    font-size: 12px !important;
}

.control-label {
    font-size: 13px;
    font-weight: 500;
    color: #2b2f4c;
    margin-bottom: 0.8rem;
}

.custom-file {
    width: 100%;
}

.custom-file input {
    opacity: 0;
    width: 0;
    height: 0;
    display: block;
}

.custom-file-label {
    padding: 0.6rem 0.85rem;
    height: 40px;
    width: 100%;
    font-weight: 400!important;
    color: #afafaf!important;
    background-color: #fff!important;
    border: 1px solid #efefef!important;
    border-radius: 5px !important;
	position: relative;
}

.custom-file-input:lang(en)~.custom-file-label::after {
    content: "Upload Files" !important;
}

.custom-file-label::after {
    height: calc(1.9em + 0.75rem + 1px) !important;
    padding: 0.6rem 0.75rem !important;
    color: #fff !important;
    content: "Upload Files" !important;
    background-color: #f55d2c !important;
    border-radius: 0 5px 5px 0 !important;
    position: absolute;
    top: 0;
    right: 0;
}


/* --- About Us --- */

.left-text {
	text-align: left;
}

.about-content p {
    margin-top: 24px !important;
    line-height: 26px;
    font-size: 14px;
    font-weight: 400;
    color: #3e3f5e;
    text-align: left;
}

.about-img {
    width: 100%;
    margin-top: 10px;
    text-align: center;
}

.about-img img {
    width: 60%;
}

.about-steps-group {
    padding: 29px 0 59px;
}

.about-step {
    text-align: center;
    padding: 0 20px;
    margin-top: 30px;
}

.about-step-img img {
    height: 60px;
}

.about-step h4 {
    font-size: 18px;
    font-weight: 700;
    color: #2b2f4c;
    margin-top: 20px;
    margin-bottom: 12px;
    text-align: center;
}

.about-step p {
    font-size: 14px;
    font-weight: 400;
    color: #3e3f5e;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
	line-height: 26px;
}

.team-item {
    width: 100%;
    border-radius: 10px;
    background: #fff;
    padding: 5px;
	text-align: center;
}

.team-img img {
    width: 100%;
    border-radius: 8px 8px 0 0;
}

.team-item h4 {
    font-size: 18px;
    font-weight: 700;
    color: #2b2f4c;
    margin-top: 21px;
    margin-bottom: 7px;
}

.team-item span {
    font-size: 14px;
    font-weight: 400;
    color: #3e3f5e;
    margin-bottom: 15px;
    display: block;
}

.team-social li {
    display: inline-block;
    margin-bottom: 20px;
    margin-right: 5px;
}

.team-social li:last-child {
    margin-right: 0;
}

.scl-btn {
    font-size: 14px;
    background: #2b2f4c;
    color: #fff;
    display: inline-block;
    height: 40px;
    width: 40px;
    border-radius: 100%;
    padding: 10px 0;
	text-align: center;
}

.how-order-gambo {
    padding: 0 0 14px;
}

.how-order-steps {
    text-align: center;
    margin-top: 30px;
    padding: 30px;
    background: #fff;
    height: 203px;
	border-radius: 10px;
}

.how-order-steps h4 {
    color: #2b2f4c;
    line-height: 26px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
	margin-top: 21px;
}

.how-order-icon i {
    width: 70px;
    height: 70px;
    display: inline-block;
    background: #f55d2c;
    color: #fff;
    font-size: 30px;
    border-radius: 100%;
    padding: 14px 0;
	text-align: center;
}

/* --- Our Blog --- */

.blog-breadcrumb {
    text-align: center!important;
    width: 100%;
    background: transparent !important;
	align-items: center;
	justify-content: center;
}	

.blog-breadcrumb .breadcrumb-item.active {
    color: #fff !important;
}

.blog-breadcrumb .breadcrumb-item a {
    color: rgba(255,255,255,0.8);
}

.blog-breadcrumb .breadcrumb-item a:hover {
    color: rgba(255,255,255) !important;
}

.title129 .blog-titlebar-heading {
    font-size: 24px;
    font-weight: 700;
    margin-top: 36px !important;
    color: #fff;
    text-align: center;
    line-height: 30px;
}

.top-post-link {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #2b2f4c;
    line-height: 22px;
    margin-bottom: 7px;
}

.top-post-link:hover {
    color: #f55d2c !important;
}

.top-posts li {
	padding: 12px 20px;
    border-bottom: 1px solid #efefef;
}

.top-posts li:last-child {
    border-bottom: 0;
}

.cntct-social {
    padding: 20px 20px 0;
}

.blog-gambo {
    padding: 65px 0 0;
}

.blog-item {
    background: #fff;
	border-radius: 10px;
	margin-bottom: 30px;
	box-shadow: 0 1px 2px 0 #e9e9e9;
}

.blog-img {
    width: 100%;
	padding: 5px 5px 0;
    position: relative;
	display: block;
}

.blog-img img {
    width: 100%;
    border-radius: 8px 8px 0 0;
}

.blog-cate-badge {
    font-size: 12px;
    font-weight: 500;
    position: absolute;
    top: 15px;
    left: 15px;
    color: #fff;
    background: #f55d2c;
    padding: 5px 10px;
    border-radius: 5px;
}

.date-icons-group {
    display: flex;
    width: 100%;
    align-items: center;
	padding: 20px;
    border-bottom: 1px solid #efefef;
}

.like-share-icons li {
    display: inline-block;
    margin-right: 15px;
}

.like-share-icons li:last-child {
    margin-right: 0;
}

.like-share-icons {
    margin-left: auto;
}

.blog-time {
    font-size: 12px;
    font-weight: 500;
    color: #b2b2b2;
    text-align: left;
}

.sz-14 {
	font-size: 14px !important;
}

.like-share {
    font-size: 14px;
    font-weight: 500;
    color: #2b2f4c;
}

.like-share span {
    margin-left: 5px;
	color: #2b2f4c;
}

.like-share:hover {
    color: #f55d2c !important;
}

.blog-detail h4 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 14px;
    text-align: left;
    color: #2b2f4c;
}

.blog-detail {
    padding: 20px;
}

.blog-detail p {
    font-size: 14px;
    font-weight: 400;
    color: #3e3f5e;
    line-height: 26px;
    margin-bottom: 12px;
    text-align: left;
}

.blog-detail a {
    font-size: 16px;
    font-weight: 500;
    color: #f55d2c;
    text-align: left;
}

.blog-detail a:hover {
    color: #f55d2c !important;
	text-decoration: underline !important;
}

.blog-btn {
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    height: 40px;
    padding: 11px 20px;
    border-radius: 5px;
    color: #fff;
    background: #f55d2c;
}

.blog-more-btn {
    text-align: center;
	padding-top: 10px;
}


/* --- Blog sidebar --- */

.pdpt-bg-left {
    margin-top: 30px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 1px 2px 0 #e9e9e9;
    float: left;
    width: 100%;
}

.blog-search-widget {
    padding: 20px;
    float: left;
    width: 100%;
}

.blog-from {
	position: relative;
}

.blog-search-widget .form-control {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: #2b2f4c;
    border: 1px solid #efefef;
    position: relative;
    padding: 0 3rem 0 1rem;
	position: relative;
	height: 40px;
	border-radius: 5px !important;
}

.blog-search-btn {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 120%;
    width: 40px;
    height: 40px;
    border: 0;
    background: transparent;
}

.top-posts {
    float: left;
    width: 100%;
}

.top-posts li {
    float: left;
    width: 100%;
}

.blog-top-item {
    float: left;
    width: 100%;
}

.post-cate-link {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: #2b2f4c;
    line-height: 26px;
    margin-bottom: 5px;
	float: left;
}

.cate-count {
	display: block;
    font-size: 14px;
    font-weight: 400;
    color: #8f91ac;
    line-height: 26px;
    margin-bottom: 0px;
	float: right;
}

.top-posts .media-list {
    padding: 20px !important;
}

.top-posts .media-list .media {
    padding: 0;
    border: none;
    border-bottom: 0;
    float: left;
    width: 100%;
}

.media-list .media .media-left {
    padding-right: 1rem;
    float: left;
}

.icon-bg-circle {
    color: #fff;
    padding: 0;
    border-radius: 8px;
    width: 70px;
    height: 70px;
    border: 0;
	object-fit: cover;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
}

.tagcloud {
    padding-bottom: 15px;
}

.tagcloud a, .tagcloud .tag-cloud-link {
    display: inline-block;
    margin-right: .5rem;
    margin-bottom: .5rem;
    padding: 0.375rem 0.5625rem;
    transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, border-color 0.25s ease-in-out, box-shadow 0.2s ease-in-out;
    border: 1px solid #efefef;
    border-radius: 5px;
    color: #2b2f4c;
    font-size: 13px !important;
    white-space: nowrap;
    line-height: 1.5;
}

.pagination-area .page-numbers {
    width: 33px;
    height: 33px;
    background-color: #fff;
	border: 1px solid #efefef;
    color: #606060;
    text-align: center;
    display: inline-block;
    border-radius: 5px;
    line-height: 34px;
    position: relative;
    margin-left: 2px;
    margin-right: 2px;
    font-size: 14px;
    font-weight: 500;
}

.pagination-area .page-numbers.current, .pagination-area .page-numbers:hover {
    background: #f55d2c;
	color: #fff !important;
	transition: all .2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
	background-image: -webkit-linear-gradient(left, rgba(230, 92, 91, 0.9), rgba(245, 93, 44, 0.9));
    background-image: linear-gradient(to right, rgba(230, 92, 91, 0.9), rgba(245, 93, 44, 0.9));
}

/* --- Blog Detail View --- */

.blog-dt-vw  {
	height: 80vh;
    display: table;
    text-align: center;
    width: 100%;
    background-position: 40% 0px;
    transition: 0s linear;
    transition-property: background-position;
}

.banner-blog {
	/*background: url(../images/blog/img-1.jpg) 40% 0px / cover no-repeat;*/
}

.banner.visible {
    opacity: 1;
}

.parallax {
    background-position: 40% 0px;
}

.blog-dt-vw  .blog-inner {
    display: table-cell;
    color: #fff;
    font-weight: 500;
    vertical-align: middle;
    background-color: rgba(0,0,0,.6);
}

.blog-dt-vw  .blog-inner h1 {
    font-size: 40px;
    font-weight: bold;
    margin: 0 auto 10px;
    max-width: 750px;
    line-height: 1.3em;
    padding: 0 15px;
}

.blog-inner .extra-info {
    font-size: 14px;
}

.entry-date {
    font-weight: 500;
}

.single-post-cat {
    margin-top: 20px;
}

.single-post-cat a {
    color: #fff;
    background: #f55d2c;
    padding: 5px 10px 4px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 12px;
    margin: 0 2px;
	line-height: 24px;
	display: inline-block;
}

.blog-des-dt142 p {
    color: #3e3f5e;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 0;
    line-height: 26px;
}

.blog-des-dt142 {
    margin-top: 42px;
}

.mt-21 {
	margin-top: 21px;
}

.mb-21 {
	margin-bottom: 21px;
}

.ss18 {
	font-size: 18px !important;
}

.leave-comment {
    margin-top: 35px;
}

.leave-comment h2 {
    font-size: 24px;
    font-weight: 700;
    text-align: left;
    width: 100%;
    margin-bottom: 13px;
    color: #2b2f4c;
}

.leave-comment span {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: #3e3f5e;
    margin-bottom: 11px;
    text-align: left;
    line-height: 26px;
}

.ptt {
	padding: 30px;
}

.all-comment {
    margin-top: 35px;
	padding-bottom: 41px;
	border-bottom: 1px solid #efefef;
}

.all-comment h2 {
    font-size: 24px;
    font-weight: 700;
    text-align: left;
    width: 100%;
    margin-bottom: 13px;
    color: #2b2f4c;
}

.cmmnt_item {
    padding: 20px;
    background: #fff;
    border-radius: 8px;
	margin-top: 30px;
}

.cmmnt_item:last-child {
    border-bottom: 0;
}

.cmmnt_usr_dt {
    display: flex;
	position: relative;
}

.cmmnt_usr_dt img {
    width: 50px;
	height: 50px;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    margin-right: 15px;
}

.tutor_name1 {
	font-size: 16px;
    font-weight: 700;
    color: #2b2f4c !important;
    text-align: left;
	margin-bottom: 0;
}

.time_145 {
    font-size: 12px;
    font-weight: 500;
    color: #b1b1b1;
    display: block;
    margin-top: 5px;
}

.rvds10 {
    font-size: 14px;
    font-weight: 400;
    margin-top: 17px;
    line-height: 26px;
    color: #3e3f5e;
    text-align: left;
	margin-bottom: 0;
}


button, input, optgroup, select, textarea {
    font-family: 'roboto', sans-serif !important;
}

textarea {
    border: 1px solid #efefef !important;
}

.form-control {
    color: #2b2f4c !important;
	font-weight: 400 !important;
}

.post-btn {
    height: 40px;
    border: 0;
    padding: 0 20px;
    color: #fff;
    background: #f55d2c;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
	margin-bottom: 13px;
}

.fcrse_3 {
    background: #fff;
    width: 100%;
    padding: 0;
    border-radius: 10px;
	margin-bottom: 30px;
	box-shadow: 0 1px 2px 0 #e9e9e9;
}

.cater_ttle {
	float: left;
	width: 100%;
	padding: 15px 20px;
}

.fcrse_3 h4 {
    font-size: 16px;
    color: #333;
	float: left;
    font-weight: 500;
    margin-bottom: 0;	
}

.allcate15 {
    width: 100%;
}

.allcate15 li {
    display: block;
    width: 100%;
}

.blogleft12 li {
    display: block;
    width: 100%;
	border-bottom: 1px solid #efefef;
}

.blogleft12 li:last-child {
    border-bottom: 0;
}

/* --- Press Page --- */ 

.ct_item {
    width: 100%;
    padding: 10px 20px;
    font-size: 14px;
    color: #686f7a;
    text-align: left;
}

.ct_item.active {
    font-weight: 600;
	color: #2b2f4c;
}

.ct_item i {
    margin-right: 10px;
    font-size: 120%;
}

.category-topics {
    border-bottom: 0;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0 !important;
    text-align: left;
    color: #2b2f4c;
    display: block;
    padding: 15px 20px;
}

.category-topics:after {
    content: '\f107';
    font-size: 14px;
	font-weight: 600;
    font-family: "Font Awesome 6 Free";
    float: right;
    margin-left: 30px;
    color: #a7a8aa;
}

.category-item1 {
    border-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0 !important;
    text-align: left;
    color: #3e3f5e;
    display: block;
    padding: 10px 20px;
}

.socl148 {
    padding: 20px;
	display: flex;
}

.twiter158 {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    color: #fff;
    border: 0;
    border-radius: 5px;
    height: 40px;
    padding: 0 15px;
    width: 50%;
	margin-right: 10px;
    background: #1da1f2;
}

.facebook158 {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    color: #fff;
    border: 0;
    border-radius: 5px;
    height: 40px;
    padding: 0 15px;
    width: 50%;
    background: #3b5998;
}

.ic45 {
    margin-right: 7px;
}

.help_link {
    padding: 15px 20px;
    text-align: center;
}

.help_link a {
    font-size: 14px;
    font-weight: 500;
    color: #2b2f4c;
    text-align: center;
}

.help_link a:hover {
    color: #f55d2c !important;
}


.press-title h2 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 15px !important;
    text-align: left;
    color: #2b2f4c;
    line-height: 24px;
}

.press-title p {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0 !important;
    text-align: left;
    color: #3e3f5e;
    line-height: 24px;
}

.press-title p a {
    color: #f55d2c;
}

.press-title p a:hover {
    color: #f55d2c !important;
	text-decoration: underline !important;
}

.press-item {
    display: block;
    background: #fff;
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    margin-top: 30px;
    box-shadow: 0 1px 2px 0 #e9e9e9;
}

.press-item span {
	font-size: 12px;
    font-weight: 500;
    margin-bottom: 0 !important;
    text-align: left;
    color: #b1b1b1;
}

.press-item h4 {
	font-size: 18px;
    font-weight: 700;
    margin-top: 15px !important;
    margin-bottom: 10px !important;
    text-align: left;
    color: #2b2f4c;
    line-height: 24px;
}

.press-item p {
	font-size: 14px;
    font-weight: 400;
    margin-bottom: 0 !important;
    text-align: left;
    color: #3e3f5e;
    line-height: 26px;
}

.policy-des-dt {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e1e1e1;
}

.policy-des-dt:last-child {
    margin-bottom: 7px;
    padding-bottom: 0;
    border-bottom: 0;
}

/* --- Sign In --- */

.sign-inup {
    padding: 80px 0;
}

.sign-form {
    display: block;
    width: 100%;
}

.sign-img {
    background: #4576b4;
    padding: 50px;
    border-radius: 5px 0 0 5px;
}

.sign-img img {
    width: 100%;
}

.sign-inner {
    padding: 0;
}

.sign-logo {
    width: 100%;
	text-align: center;
}

.sign-logo img {
    width: 150px;
}

.form-dt {
    margin-top: 50px;
    border: 1px solid #efefef;
	position: relative;
	border-radius: 10px;
	background: #fff;
}

.form-inpts {
	padding: 38px 30px 0;
}

.form-title {
    text-align: center;
	margin-bottom: 37px;
}

.form-title h6 {
	font-weight: 700;
	color: #2b2f4c;
	font-size: 18px;
}

.lgn_input {
    padding: 0 1rem 0 3rem !important;
    height: 50px !important;
}

.pos_rel {
	position: relative;
}

.lgn_icon {
    position: absolute;
    top: 12px;
    left: 2%;
    font-size: 18px;
    color: #2b2f4c;
}

.form-control:focus+.lgn_icon  {
	color: #f55d2c;
}

.login-btn {
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    height: 40px;
    border: 0;
    color: #fff;
    background: #f55d2c;
    border-radius: 5px;
    text-align: center;
}

.signup-link {
	margin-top: 30px;
	border-radius: 0 0 10px 10px;
    text-align: center;
    padding: 1.5rem;
	background: #f55d2c;
	transition: all .2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
	background-image: -webkit-linear-gradient(left, rgba(230, 92, 91, 0.9), rgba(245, 93, 44, 0.9));
    background-image: linear-gradient(to right, rgba(230, 92, 91, 0.9), rgba(245, 93, 44, 0.9));
}

.signup-link p {
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 0;
}

.signup-link p a {
    color: #fff;
    font-size: 12px;
    background: #2b2f4c;
    padding: 5px 15px;
    border-radius: 5px;
    display: inline-block;
    margin-left: 5px;
}

.signup-link p a:hover {
    color: #fff !important;
	box-shadow: 0px 5px 5px rgba(0,0,0,0.2);
}

.sign-inup .copyright-text {
    color: #2b2f4c;
}

.password-forgor {
	text-align: center;
}

.password-forgor a {
    display: block;
    margin-top: 30px;
    font-size: 14px;
    font-weight: 500;
    color: #2b2f4c;
}

.password-forgor a:hover {
    color: #f55d2c !important;
}

/* --- Contact Us --- */

.panel-group {
    width: 100%;
	margin-top: 34px;
	margin-bottom: 14px;
}

.panel-title {
    vertical-align: middle;
}

.panel-default {
    width: 100%;
	border-top: 0;
}

.panel-default:first-child {
	border-top: 0;
}

.panel-body {
    font-size: 14px;
    font-weight: 400;
    color: #3e3f5e;
    line-height: 26px;
    padding-bottom: 20px;
    text-align: left;
    padding: 20px;
    background: #fff;
	border-radius:  0 0 10px 10px;
    border-top: 1px solid #efefef;
}

.panel-default {
    width: 100%;
    border-top: 0;
}

.panel-title {
    vertical-align: middle;
}

.panel-title a {
    font-size: 16px;
    font-weight: 500;
    color: #2b2f4c !important;
    padding: 15px 20px 18px;
    display: block;
    line-height: 26px;
    background: #fff;
    border-radius: 10px 10px 0 0;
    margin-top: 10px;
}

.panel-title a.collapsed {
    border-radius: 10px;
}

.chck_icon {
    color: #f55d2c;
    margin-right: 10px;
}

.panel-title > a:before {
    float: right !important;
    font-family: 'Roboto', sans-serif;
    content:"\f058";
    padding-right: 0px;
	font-size: 16px;
	padding-top: 2px;
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
}

.panel-title > a.collapsed:before {
    float: right !important;
    content:"\f058";
	font-family: "Font Awesome 5 Free";
	font-weight: 400;
}

.color-pink {
	color: #f55d2c;
}

.contact-title h2 {
    font-size: 24px;
    font-weight: 700;
    margin-top: 0 !important;
    text-align: left;
    margin-bottom: 12px;
	color: #2b2f4c;
}

.contact-title p {
    font-size: 14px;
    font-weight: 400;
    margin-top: 0 !important;
    text-align: left;
    margin-bottom: 0;
	color: #3e3f5e;
	line-height: 26px;
}

.contact-form {
    background: #fff;
    border-radius: 10px;
    padding: 20px 30px 30px;
	margin-top: 34px;
	margin-bottom: 14px;
}

/* --- Product Request --- */

.bootstrap-tagsinput .tag {
	margin-right: 2px;
    color: #fff;
    background: #f55d2c;
    font-weight: 400;
    padding: 5px 10px;
    border-radius: 3px;
    line-height: 26px;
}

.bootstrap-tagsinput {
    border: 1px solid #efefef !important;
    box-shadow: none !important;
	width: 100%;
	height: auto;
	text-align: left;
}

.bootstrap-tagsinput input {
    padding: 7px 10px !important;
	font-weight: 500;
	border-radius: 5px;
}

.request-products {
	margin-top: 30px;
}

.rqst-btn {
	width: 100%;
}

.request-grid {
    margin-top: 64px;
}

/* --- Faq Page --- */

.faq-dt {
    padding: 50px;
    background: #2b2f4c;
    position: relative;
}

.faq-dt:before {
    content: "";
    /*background: url(../images/career/default.jpg) no-repeat center;*/
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    pointer-events: none;
    background-size: cover;
    opacity: 0.1;
}

.panel-body p {
    font-size: 14px;
    font-weight: 400;
    color: #3e3f5e;
    line-height: 26px;
    margin-bottom: 0;
}

/* --- Offers Page --- */

.offers-item {
	display: block;
	margin-top: 30px; 
    background: #fff;
    padding: 5px;
    border-radius: 10px;
    position: relative;
	box-shadow: 0 1px 2px 0 #e9e9e9;
}

.offer-img img {
    width: 100%;
	border-radius: 8px 8px 0 0;
}

.offers-text {
    padding: 20px 15px;
}

.offers-text h4 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 13px;
    text-align: left;
    color: #2b2f4c;
}

.offers-text p {
    font-size: 14px;
    font-weight: 400;
    color: #3e3f5e;
    line-height: 26px;
    text-align: left;
	margin-bottom: 0;
}

.mb-14 {
	margin-bottom: 14px;
}

/* --- Bill Page --- */

.report-btn {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    background: #f55d2c;
    display: inline-block;
    padding: 8px 16px;
    border-radius: 5px;
}

.bill-dt-bg {
    padding-top: 65px;
}

.bill-detail {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 1px 2px 0 #e9e9e9;
	margin: 37px 0;
}

.bill-dt-step {
    padding: 30px;
    border-bottom: 1px solid #efefef;
}

.bill-dt-step:last-child {
    border-bottom: 0;
}

.bill-title h4 {
    margin-bottom: 20px !important;
    font-size: 18px;
    font-weight: 700;
    color: #2b2f4c;
}

.itm-ttl {
    display: block;
    font-weight: 500;
    font-size: 16px;
    text-align: left;
    margin-bottom: 10px;
    color: #2b2f4c;
}

.item-prdct {
	font-size: 14px;
	font-weight: 400;
	color: #3e3f5e;
	text-align: left;
	line-height: 24px;
	margin-right: 10px;
}

.item-prdct:last-child {
	margin-right: 0;
}

.item-prdct:after {
	content: ',';
	padding-left: 3px;
}

.item-prdct:last-child:after {
	content: '';
}

.bill-address {
    font-size: 14px;
    font-weight: 400;
    color: #3e3f5e;
    margin-bottom: 0;
    line-height: 26px;
}

.bill-dt-sl {
	font-size: 14px;
    font-weight: 400;
    color: #2b2f4c;
    margin-bottom: 0;
    line-height: 30px;
}

.descp-bll-dt {
	color: #8f91ac;
}

.dly-loc {
	color: #3e3f5e;
}

.dlr-ttl25{
	color: #f55d2c;
}

.bill-bottom {
    display: flex;
    align-items: center;
}

.thnk-ordr {
	font-size: 14px;
    font-weight: 500;
    color: #2b2f4c;
    margin-bottom: 0;
    line-height: 26px;
}

.print-btn {
	margin-left: auto;
    padding: 9px 20px;
    border: 1px solid #f55d2c;
    background: #f55d2c;
    color: #fff;
    border-radius: 5px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    display: inline-block;
}


.main-form .btn-light {
	min-height: 40px !important;
    display: block !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #717171 !important;
    background-color: #fff !important;
    border: 1px solid #efefef !important;
    padding: 5px 20px !important;
    border-radius: 5px !important;
    transition: none !important;
}


.main-form .ck-select .btn-light {
    min-height: 50px !important;
    height: 50px !important;
    padding: 14px 20px !important;
}

.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 100% !important;
}

.bootstrap-select .dropdown-menu.inner {
    padding: 0px !important;
    border: 0 !important;
    box-shadow: none !important;
    display: inline-block;
}

.main-form .bootstrap-select .dropdown-toggle:focus, .main-form .bootstrap-select>select.mobile-device:focus+.dropdown-toggle {
    outline: none !important;
    outline: none !important;
    outline-offset: -2px;
	border-color: #f55d2c !important;
	box-shadow: none !important;
}

.bootstrap-select .dropdown-item.active, .bootstrap-select .dropdown-item:active {
	border-radius: 0 !important;
	background: #f55d2c;
	color: #fff !important;
	transition: all .2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
	background-image: -webkit-linear-gradient(left, rgba(230, 92, 91, 0.9), rgba(245, 93, 44, 0.9));
    background-image: linear-gradient(to right, rgba(230, 92, 91, 0.9), rgba(245, 93, 44, 0.9));
}

.bootstrap-select.dropdown-item.active, .bootstrap-select .dropdown-item:active {
    color: #fff !important;
	border-radius: 0 !important;
    text-decoration: none;
    background: #f55d2c;
	color: #fff !important;
	transition: all .2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
	background-image: -webkit-linear-gradient(left, rgba(230, 92, 91, 0.9), rgba(245, 93, 44, 0.9));
    background-image: linear-gradient(to right, rgba(230, 92, 91, 0.9), rgba(245, 93, 44, 0.9));
}

.bootstrap-select .dropdown-item {
    color: #000 !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    padding: 10px 20px !important;
}

.bootstrap-select>.dropdown-toggle:after {
    margin-left: 0 !important;
}