.checkout__form__input{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.m-o{
    margin: 0px;
}
.login_label{
    align-items: center;
}
.mb-35{
    margin-bottom: 35px;
}
.footer__widget ul li a:hover{
    text-decoration: none;
    color: antiquewhite !important;
    font-size: 14px !important;
}
.cart_item{
    border-bottom: 1px solid #dddddd;
}
.display-none{
    display: none;
}
.flex-row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.flex-space-between{
    justify-content: space-between;
}
.flex-space-around{
    justify-content: space-around;
}
.checkout__form__input .details{
    border-radius: 5px;
    color: #666;
    font-size: 16px;
    height: 50px;
    padding-left: 20px;
    width: 100%;
}
.checkout__form__input p {
    color: #444;
    font-weight: 500;
    margin-bottom: 10px;
    width: 41%;
}
.flex-row-end{
    display: flex;
    justify-content: end;
    align-items: center;
    flex-direction: row;
}
.logoutbtn{
    color: red !important;
    font-size: 16px !important;
}
.loggedinuser{
    font-size: 16px !important;
}
.action-btns .site-btn2{
    font-size: 13px;
    color: #ca1515;
    background: #ffffff;
    font-weight: 600;
    border: 1px solid #d5d5d5;
    text-transform: uppercase;
    display: inline-block;
    padding: 3px 18px;
    border-radius: 50px;
}
.action-btns .site-btn2{
    font-size: 13px;
    color: #ffffff;
    background: #ca1515;
    font-weight: 600;
    border: 1px solid #d5d5d5;
    text-transform: uppercase;
    display: inline-block;
    padding: 3px 18px;
    border-radius: 50px;
}
.red{
    color: red;
}
.content-center{
    justify-content: center;
}
.header2 {
    position: fixed;
    z-index: 100;
    width: 100%;
    background: #fff;
    box-shadow: 0px 0.052083333in 11.25pt -9px rgb(0 0 0 / 10%);
    top: 74px;
    transition: top 1s ease;
    padding: 4px 10px 23px;
}
.search-txt {
    padding: 5px 16px;
    color: #2b2f4c !important;
    width: 61%;
    /* border-radius: 19px; */
    font-size: 20px;
    border: none;
    border-bottom: 1px solid #d18a34;
}
.tip{
    position: absolute;
    right: -12px;
    top: -11px;
    height: 18px;
    width: 18px;
    background: #111111;
    font-size: 10px;
    font-weight: 500;
    color: #ffffff;
    line-height: 18px;
    text-align: center;
    border-radius: 50%;
}
.order-confirm h4{
    border-bottom: 1px solid #e1e1e1;
    color: rgb(90 90 90);
    font-weight: 600;
    margin-bottom: 20px;
    padding-bottom: 19px;
    text-transform: uppercase;
    letter-spacing: 0.50px;
    font-size: 20px;
}
.confirm-text{
    font-size: 15px;
    /* width: 40%; */
    margin: 17px 284px;
}
.order-details{
    font-size: 15px;
    margin: 17px 284px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}
.order-label{
    font-size: 14px;
    width:120px;
    font-weight: 700;
    color:#606060;
}
.order-text{
    font-size: 13px;
    font-weight: 700;
}
.align-item-center{
    align-items: center;
}
.flex-column{
    display: flex;
    flex-direction: column;
}